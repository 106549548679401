import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
import { getToken } from "@/utils/auth";
import router from "@/router";
import store from "@/store";

axios.defaults.transformRequest = [
  function (data) {
    if (Object.prototype.toString.call(data) === "[object FormData]") {
      return data;
    } else {
      return qs.stringify(data);
    }
  },
];

export const baseURL = "https://rabbitgames.cc"; //? 本地开发接口

const service = axios.create({
  baseURL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    Toast.loading({
      message: "読込中...",
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    // do something before request is sent
    config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
    if (getToken()) {
      config.headers["token"] = getToken();
    }
    return config;
  },
  (error) => {
    Toast.clear();
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    Toast.clear();
    const { code, data, msg, show } = response.data;
    switch (code) {
        case 1:
            return data
        case 0:
            Toast(msg);
            return Promise.reject(msg)
        case -1:
            Toast({
              message: msg,
              onClose: ()=>{
                store.dispatch("logout");
                router.push('/my');
              },
            });
            return Promise.reject(msg)
        default:
            return data
    }
  },
  (error) => {
    Toast.clear();
    Toast(error.msg);
    return Promise.reject(error);
  }
);
export default service;
