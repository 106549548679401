<template>
  <div class="page-box">
    <div class="session_1" v-if="is_login">
      <div class="centent-box">
        <div class="box_1">
          <span class="title">保有コイン</span>
          <div class="monery" @click="goto('/loglist')">
            <img src="@/assets/images/jilu.png" />
          </div>
        </div>
        <div class="box_2">
          <div class="item-box">
            <span>{{ userInfo.robot_money || 0 }}</span>
            <p>クレジット</p>
          </div>
          <div class="item-box">
            <span>{{ userInfo.user_money || 0 }}</span>
            <p>コイン</p>
          </div>
        </div>
        <div class="box_3">
          <div class="btn" @click="goto('/history')">実績レポ一トを見る</div>
        </div>
      </div>
    </div>
    <div class="no-login" v-else>
       <div class="form-box">
        <div class="btn-boxs" @click="LoginClick"><img src="@/assets/images/line.png" />Line ログイン</div>
      </div>
    </div>
    <div class="session_2">
      <div class="title-box">アカウント</div>
      <div class="tab-box">
        <div class="item-box" @click="goto('/information')">
          <div class="icon-box">
            <img src="@/assets/images/icon_12.png" alt="" />
          </div>
          <p>アカウント情報</p>
          <div class="arr-box">
            <img src="@/assets/images/icon_14.png" alt="" />
          </div>
        </div>
        <div class="item-box" @click="goto('/experience')">
          <div class="icon-box">
            <img src="@/assets/images/icon_13.png" alt="" />
          </div>
          <p>レシチャレ参加履歴</p>
          <div class="arr-box">
            <img src="@/assets/images/icon_14.png" alt="" />
          </div>
        </div>
        <div class="item-box" @click="goto('/selectpay')">
          <div class="icon-box">
            <img src="@/assets/images/sk.png" alt="" />
          </div>
          <p>出金方式</p>
          <div class="arr-box">
            <img src="@/assets/images/icon_14.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="session_3">
      <div class="img-box" @click="goto('/serve')">
        <img src="@/assets/images/img_4.jpg" alt="" />
      </div>
    </div>
    <div class="session_4">
      <div class="title-box">サポ一ト</div>
      <div class="box_1">
        <div class="user_id-box">
          <div class="title">1-ザ-1D</div>
          <div class="id-box">{{userInfo.sn}}</div>
          <div class="icon-box" @click="copyBtn">
            <img src="@/assets/images/icon_16.png" alt="" />
          </div>
        </div>
        <div class="tab-box">
          <p>利用規約</p>
          <div class="arr-box">
            <img src="@/assets/images/icon_14.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Kefu></Kefu>
    <Tabbar></Tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/Tabbar.vue";
import Kefu from "@/components/Kefu.vue";
import { getUserCenter, loginuser } from '@/api/user';
import { setToken } from '@/utils/auth';
export default {
  name: "My",
  components: {
    Tabbar,
    Kefu
  },
  data() {
    return {
      is_login: this.$store.state.isLogin,
      userInfo: {},
      loginurl: 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2005681335&redirect_uri=https%3A%2F%2Frabbitgames.cc%2Fmobile%2Fmy&state=1234assd5abcde&scope=profile%20openid',
      code: this.$route.query.code,
    };
  },
  watch: {
    is_login: {
      handler(val){
        console.log(val);
      },
      deep: true
    }
  },
  created() {
    if (this.is_login) {
      this.getUserInfo();
    }else{
      if(this.code){
        this.logins();
      }
    }
  },
  methods: {
    logins(){
      let param = {
          code: this.code
      }
      let that = this;
      loginuser(param)
          .then(data => {
          let token = data.token;
          if (token) {
              setToken(token);
              that.is_login = true;
              that.$store.commit("changeUserStatus");
              that.getUserInfo();
              if(data.url == 'information'){
                that.$toast({
                  message: "サインイン成功",
                  onClose: ()=>{
                    that.$router.push("/"+data.url);
                  },
                });
              }
          }
      })
      .catch(err => {
        console.log(err);
      })
    },
    LoginClick(){
      location.href = this.loginurl;
    },
    getUserInfo() {
      let that = this;
      getUserCenter()
        .then(data => {
          that.userInfo = data;
          if(!data.lastname || !data.firstname || !data.mobile || !data.email || !data.money_password){
            this.$router.push("/"+data.url);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    copyBtn() {
      this.$copyText("" + this.userInfo.sn + "").then(e=> {
        this.$toast("コピー成功!");
      });
    },
    goto(url) {
      if (this.is_login) {
        this.$router.push(url);
      } else {
        var _this = this;
        this.$toast({
          message: "未登録!",
          onClose: ()=>{
            location.href = _this.loginurl;
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-box {
  padding-bottom: 100px;
  max-width: 750px;
}
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 35px;
  
  .centent-box {
    background: #f0f0f0;
    border-radius: 30px;
    padding-top: 48px;
    padding-bottom: 30px;
    .box_1 {
      padding-left: 50px;
      padding-right: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 58px;
      .title {
        font-size: 32px;
        color: #222222;
        font-weight: 700;
      }
      .monery {
        img {
          height: 50px;
        }
      }
    }
    .box_2 {
      position: relative;
      display: flex;
      justify-content: center;
      &::before {
        position: absolute;
        content: "";
        width: 1px;
        height: 70px;
        background: #9e9e9e;
        top: 50%;
        transform: translateY(-50%);
      }
      .item-box {
        margin-left: 70px;
        margin-right: 70px;
        span {
          font-size: 32px;
          color: #222222;
          font-weight: 700;
        }
        p {
          font-size: 26px;
          color: #222222;
        }
      }
    }
    .box_3 {
      text-align: center;
      margin-top: 50px;
      .btn {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background: #2d2d2d;
        width: 288px;
        border-radius: 30px;
        color: #fff;
      }
    }
  }
}
.no-login{
  height: 280px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  margin-top: 35px;
  .form-box{
    display: grid;
    width: 100%;
    place-content: center;
    .btn-boxs{
      height: 100px;
      line-height: 100px;
      max-width: 750px;
      width: 90vw;
      box-sizing: border-box;
      background:#fff;
      border: #e8e8e8 1px solid;
      text-align: center;
      border-radius: 50px;
      color:#333;
      font-size: 32px;
      font-weight: bold;
      position: relative;
      img {
        width: 50px;
        position: absolute;
        left: 20px;
        top: 30px;
      }
    }
  }
}
.session_2 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 40px;
  .title-box {
    font-size: 38px;
    color: #222222;
    font-weight: 700;
  }
  .item-box {
    position: relative;
    height: 106px;
    line-height: 106px;
    padding-left: 50px;
    font-size: 26px;
    .icon-box {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 32px;
      }
    }
    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #bebebf;
    }
    .arr-box {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      img {
        height: 20px;
      }
    }
  }
}
.session_3 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 50px;
  .img-box {
    img {
      width: 100%;
    }
  }
}
.session_4 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 80px;
  .title-box {
    font-size: 38px;
    color: #222222;
    font-weight: 700;
  }
  .tab-box {
    position: relative;
    height: 106px;
    line-height: 106px;
    .arr-box {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      img {
        height: 20px;
      }
    }

    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0px;
      right: 0;
      height: 1px;
      background: #bebebf;
    }
    &.tab_1 {
      padding-left: 70px;
      &::before {
        left: 70px;
      }
      .icon-box {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        img {
          height: 32px;
        }
      }
    }
  }
  .user_id-box{
    position: relative;
    margin-top: 30px;
    .title{
      font-weight: 700;
      font-size: 28px;
      color:#222222;
    }
    .id-box{
      height: 90px;
      line-height: 90px;
      border-bottom: 1px solid #c8c8c9;
    }
    .icon-box{
      position: absolute;
      bottom: 30px;
      right: 20px;
      img{
        height: 33px;
      }
    }
  }
}
</style>
