<template>
    <div class="employ-box">
      <div class="session_1">
        <div class="top-box">
            <div class="back-box" @click="goto('/employ')">
              <span><img src="@/assets/images/jt.png" /></span>
            </div>
            <span class="title-box">出金方式</span>
            <span class="add-box" @click="goto('/addbank')">追加</span>
        </div>
      </div>
      <div class="van-list">
        <div class="item" v-for="item in pay_list">
            <div class="addressbox">
                <div style="width: 80%; height: 40px; line-height: 40px; float: left;">
                    <span class="fzmmm fe6im">{{ item.type==2 ? item.bank_name : '' }}</span>
                </div>
                <div class="plane">{{ item.type==1 ? 'PAYPAY' : '銀行振り込み' }}</div>
            </div>
            <div class="address">
                <span class="fzmmm fe6im">{{ item.type==1 ? item.mobile : item.bank_no }}</span>
            </div>
        </div>
      </div>
      <Tabbar></Tabbar>
    </div>
  </template>
  
  <script>
  import Tabbar from "@/components/Tabbar.vue";
  import { banklist } from '@/api/pay';
  export default {
    name: "Employ",
    components: {
      Tabbar,
    },
    data() {
      return {
        pay_list: [],
      };
    },
    mounted(){
      this.paypay();
    },
    methods: {
      goto(url) {
        this.$router.push(url);
      },
      paypay(){
        banklist().then((res) => {
            this.pay_list = res;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  };
  </script>
  
  <style scoped lang="scss">
  .employ-box {
    padding-bottom: 150px;
  }
  .session_1 {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 45px;
    .top-box {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #000;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .back-box {
        span {
          display: inline-block;
          img {
            height: 50px;
          }
        }
      }
    }
  }
  .van-list {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    .item {
        color: #e6e6e6;
        font-size: 26px;
        border-radius: 20px;
        margin-bottom: 20px;
        background: #1b1d2a;
        .addressbox {
            width: 100%;
            box-sizing: border-box;
            height: 100px;
            line-height: 100px;
            padding: 0px 4vw;
            border-top-left-radius: 2.666667vw;
            border-top-right-radius: 2.666667vw;
            display: flex;
            justify-content: space-between;
            .plane {
                padding: 0px 1.333333vw;
                height: 5.333333vw;
                line-height: 5.333333vw;
                background: green;
                border-radius: 1.333333vw;
                font-size: 2.666667vw;
                margin-top: 2.666667vw;
                white-space: nowrap;
            }
        }
        .address {
            margin-top: 2.666667vw;
            padding: 2.666667vw;
            word-wrap: break-word;
            word-break: normal;
        }
    }
  }

  </style>
  