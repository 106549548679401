export const cutMoneyFiter = (amount) => {
	if(!amount) {
		return 0;
	}
	//强制保留两位小数
	let f = parseFloat(amount);
	// if (isNaN(f)) return false;
	// 	f = Math.round(amount * 100) / 100;
	let s = f.toString();
	// let rs = s.indexOf('.');
	// if (rs < 0) {
	// 	rs = s.length;
	// 	s += '.';
	// }
	// while (s.length < (rs + 1) + 2) {
	// 	s += '0';
	// }
	//每三位用一个逗号隔开
	let leftNum=s.split(".")[0];
	// let rightNum="."+s.split(".")[1];
	let result;
	//定义数组记录截取后的价格
	let resultArray=new Array();
	if(leftNum.length>3){
		let i=true;
		while (i){
			resultArray.push(leftNum.slice(-3));
			leftNum=leftNum.slice(0,leftNum.length-3);
			if(leftNum.length<4){
				i=false;
			}
		}
		//由于从后向前截取，所以从最后一个开始遍历并存到一个新的数组，顺序调换
		let sortArray=new Array();
		for(let i=resultArray.length-1;i>=0;i--){
			sortArray.push(resultArray[i]);
		}
		result=leftNum+","+sortArray.join(",");//+rightNum
	}else {
		result=s;
	}
	return result;
}

export const getNowFormatDate = () => {
	let date = new Date(),
	  	year = date.getFullYear(), //获取完整的年份(4位)
	  	month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
	  	strDate = date.getDate() // 获取当前日(1-31)
	if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
	if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
	return `${year}/${month}/${strDate}`;
}

export const formatNumber = (num) => {
	num = Number(num);
	if (num == 0 || (num > 0 && num < 10000)) {
		return num + '';
	} else {
		return (num / 10000).toFixed(2) + '万';
	}
}
