import request from '@/utils/request'

export function tasklist(params) {
  return request({
    url: '/api/tasks/lists',
    method: 'GET',
    params
  })
}

export function taskshow(params) {
  return request({
    url: '/api/tasks/detail',
    method: 'GET',
    params
  })
}

export function tasksreceive(data) {
  return request({
    url: '/api/tasks/receive',
    method: 'POST',
    data
  })
}

export function mytasklist(params) {
  return request({
    url: '/api/taskslogs/lists',
    method: 'GET',
    params
  })
}

export function taskvideo() {
  return request({
    url: '/api/tasks/video',
    method: 'GET',
  })
}

export function receivevideo(data) {
  return request({
    url: '/api/tasks/receivevideo',
    method: 'POST',
    data
  })
}

export function ischance() {
  return request({
    url: '/api/tasks/ischance',
    method: 'GET',
  })
}
