import request from '@/utils/request'

export function loginusers(data) {
  return request({
    url: '/api/login/account',
    method: 'POST',
    data
  })
}

export function loginuser(data) {
  return request({
    url: '/index/index/accesstoken',
    method: 'POST',
    data
  })
}

export function getUserCenter(header) {
  return request({
    url: '/api/user/center',
    method: 'get',
    header
  })
}

export function logout() {
  return request({
    url: '/api/login/logout',
    method: 'POST'
  })
}

export function editUser(data) {
  return request({
      url: '/api/user/setInfo',
      method: 'POST',
      data
  })
}

export function statistics() {
  return request({
      url: '/api/user/statistics',
      method: 'GET'
  })
}

export function accountlog(params) {
  return request({
    url: '/api/accountlog/lists',
    method: 'GET',
    params
  })
}