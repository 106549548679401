<template>
  <div class="page-box">
    <div class="title-box">ログイン</div>
    <div class="form-box">
     <div class="input-box">
        <span>携帯電話番号</span>
        <input type="text" v-model="phone" placeholder="電話番号を入力">
      </div>
      <div class="input-box">
        <span>パスワード</span>
        <input type="text" v-model="password" placeholder="パスワードを入力">
      </div>
      <div class="btn-box" @click="LoginClick">ログイン</div>
    </div>
    <div :class="['hint-box',is_hint?'active':'']" @click="hintClick">
      <span>
        <van-icon name="circle" />
        <van-icon name="checked"  class="active" />
        <em>パスワードを記憶する</em>
      </span>
    </div>
    <div class="other-box">
      <router-link to="/register">アカウントをお持ちでない場合は、サインアップしてください</router-link>
    </div>
  </div>
</template>

<script>
import { loginusers } from '@/api/user';
import { setToken } from '@/utils/auth';
export default {
  name: "logins",
  data() {
    return {
      is_hint:false,
      phone: localStorage.getItem("account") || '',
      password: localStorage.getItem("password") || ''
    };
  },

  mounted() {

  },

  methods: {
    hintClick(){
      if (!this.phone) {
        this.$toast("電話番号を入力");
        return false;
      }
      if (!this.password) {
        this.$toast("パスワードを入力");
        return false;
      }
      this.is_hint = !this.is_hint;
      if (this.is_hint == true) {
        localStorage.setItem("account",this.phone);
        localStorage.setItem("password",this.password);
      } else {
        localStorage.removeItem("account");
        localStorage.removeItem("password");
      }
    },
    async LoginClick(){
      if (!this.phone) {
        this.$toast("電話番号を入力");
        return false;
      }
      if (!this.password) {
        this.$toast("パスワードを入力");
        return false;
      }
      var param = {
        account: this.phone,
        password: this.password,
        terminal: 3,
        scene: 1
      }
      await loginusers(param)
        .then(data => {
          let token = data.token;
          if (token) {
            setToken(token);
            this.$store.commit("changeUserStatus");
            this.$toast({
              message: "サインイン成功",
              onClose: ()=>{
                this.$router.push("/my");
              },
            });
          }
        })
        .catch(err => {
          console.log(err.message)
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-box{
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 45px;
  .title-box{
    font-size: 32px;
    color:#000;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .input-box{
    margin-bottom: 30px;
    position: relative;
    padding-left: 170px;
    box-sizing: border-box;
    span{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 26px;
    }
    input,select{
      width: 100%;
      height: 72px;
      line-height: 72px;
      font-size: 26px;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      outline: none;
      border:1px solid #dfdfdf;
      border-radius: 10px;
      
    }
  }
  .btn-box{
    height: 82px;
    line-height: 82px;
    width: 100%;
    background:#4f73ee;
    text-align: center;
    border-radius: 50px;
    color:#fff;
    font-size: 32px;
  }
  .hint-box{
    text-align: left;
    margin-top: 40px;
    margin-bottom: 80px;
    span{
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .van-icon{
        margin-right: 5px;
        &.active{
          display: none;
        }
      }
      em{
        font-size: 26px;
        color:#7b7b7b;
      }
      
    }
    &.active{
      span{
        .van-icon{
        display: none;
          &.active{
            display: inline-block;
            
          }
      }
      }
    }

  }
  .other-box{
    text-align: center;
    font-size: 28px;
     a{
      color:#4f73ee;
    }
  }
}

</style>
