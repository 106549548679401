<template>
  <div class="home">
    优惠
    <Tabbar></Tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/Tabbar.vue";

export default {
  name: "Discounts",
  components: {
    Tabbar
  },
};
</script>

<style scoped lang="scss">

</style>
