<template>
    <div class="session_20" v-if="orderinfo.sn" @click="gotoorderinfo(orderinfo)">
        <div class="payment_process_info"><p><span class="unpaid_info_icon">手続き中</span>未払い、またはお支払い確認中の銀行振り込みがあります。</p ></div>
    </div>
</template>

<script>
import axios from "axios";
import { baseURL } from '@/utils/request'
import { getToken } from "@/utils/auth";
export default {
    name: "app",
    data() {
        return {
            is_login: this.$store.state.isLogin,
            timer: null, //定义计算器
            orderinfo: {}
        }
    },
    mounted() {
        this.start();
    },
    methods: {
        start(){
            if(this.is_login){
                this.timer = setInterval(() => {
                    this.getPayorder();
                }, 3000);
            }
        },
        qingchu(){
            // 清除定时器
            clearInterval(this.timer);
        },
        gotoorderinfo(orderinfo) {
            this.$router.push({
                path: "/payorder",
                query: {
                    order_id: orderinfo.sn,
                    code: orderinfo.code
                }
            });
        },
        getPayorder() {
            let _this = this;
            axios({
                method: 'get',
                url: baseURL + '/api/index/payorder',
                headers: {'token': getToken()},
            })
            .then(function (res) {
                let data = res.data.data;
                _this.orderinfo = data;
                _this.$emit('return-orderinfo', data);
            });
        }
    },
    beforeDestroy() {
      // 清除定时器
      clearInterval(this.timer);
    },
}
</script>

<style scoped lang="scss">
.session_20 {
  height: 80px;
  line-height: 80px;
  background-color: #ffe53f;
  width: 100%;
  display: block;
  .payment_process_info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    span {
      background-color: #e85298;
      line-height: 60px;
      height: 60px;
      border-radius: 30px;
      padding: 10px 15px;
      color: #fff;
      margin-right: 10px;
    }
  }
}
</style>