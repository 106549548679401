import { render, staticRenderFns } from "./Serve.vue?vue&type=template&id=0421c678&scoped=true"
import script from "./Serve.vue?vue&type=script&lang=js"
export * from "./Serve.vue?vue&type=script&lang=js"
import style0 from "./Serve.vue?vue&type=style&index=0&id=0421c678&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0421c678",
  null
  
)

export default component.exports