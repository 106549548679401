<template>
  <div class="page-box">
    <div class="session_1">
      <div class="back-box">
        <span @click="goto('/employ')"
          ><img src="@/assets/images/icon_35.png" alt=""
        /></span>
      </div>
      <h3>購入額の選択</h3>
      <p>{{payname}}を利用して残高をチャージします。</p>
    </div>
    <div class="session_2">
      <div class="centent-box">
        <div class="box_1">
          <ul>
          <li>
            <p>{{payname}}を利用して残高をチャージします。</p>
            <p>チャージする金額を選択、もしくは任意の額を入力してください。</p>
          </li>
          <li>
            <p>購入可能な金額は、<strong>{{ minpay }}</strong> ～ <strong>{{ maxpay }}</strong>クレジットです。</p>
          </li>
        </ul>
        </div>
        <div class="box_2">
          <dl>
            <dt>購入額</dt>
            <dd>
              <div class="input-box" v-for="(item,index) in radiolist" :key="index">
                <input name="selectAmount" type="radio" @click="xuanzhe(item)" :id="'input_'+(index + 1)"><label :for="'input_'+(index + 1)">{{ item.text }} クレジット</label>
              </div>
            </dd>
          </dl>
        </div>
        <div class="box_3">
          <div class="title-box">1クレジット単位で任意の額を入力</div>
          <div class="input-box">
            <input :placeholder="minpay + '～' + maxpay + 'の間'" v-model="money" type="text" class="ts">クレジット
          </div>
        </div>
      </div>
    </div>
    <div class="session_3">
       <div class="btn-box" @click="payorder">同意して次へ</div>
       <div class="black-box" @click="goto('/employ')">キャンセル</div>
    </div>
  </div>
</template>

<script>
import { createorder } from "@/api/pay";
export default {
  name: 'pay',
  data() {
    return {
      payname: this.$route.query.name,
      paytype: this.$route.query.paytype,
      minpay: '3,000',
      maxpay: this.$route.query.paytype==1 ? '80,000' : this.$route.query.paytype==2 ? '500万門' : '80,000',
      money: '',
      radiolist: [
        {'id':1,'money':10000,'text':'10,000'},
        {'id':2,'money':30000,'text':'30,000'},
        {'id':3,'money':50000,'text':'50,000'},
        {'id':4,'money':80000,'text':'80,000'},
      ]
    };
  },
  mounted() {
    
  },
  methods: {
    goto(url){
      this.$router.push(url);
    },
    xuanzhe(item){
      this.money = item.money;
    },
    payorder(){
      let data = {
        'paytype': this.paytype,
        'money': this.money
      };
      createorder(data).then((res) => {
        this.$toast({
          message: "オーダー作成に成功しました",
          onClose: ()=>{
            this.$router.push({
              path: "/payorder",
              query: {
                order_id: res.order_id,
                code: res.code
              },
            });
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.session_1{
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 25px;
  .back-box {
    height: 100px;
    line-height: 100px;
    span {
      display: inline-block;
      padding-right: 30px;
      img {
        height: 40px;
      }
    }
  }
  h3{
    font-size: 32px;
    color:#000;
    margin-bottom: 25px;
  }
  p{
    font-size: 26px;
  }
}
.session_2{
  padding-left: 30px;
  padding-right: 30px;
  .centent-box{
    border: 1px solid #e9e9e9;
    border-radius: 30px;
    padding:30px;
    box-sizing: border-box;
    .box_1{
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 35px;
      margin-bottom: 35px;
      li{
        font-size: 26px;
        line-height: 1.6;
        margin-bottom: 20px;
        margin-top: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .box_2{
      dt{
        font-size: 30px;
        font-weight: 700;
        color:#000;
        margin-bottom: 15px;
      }
      .input-box{
        height: 80px;
        line-height: 78px;
        border: 2px solid #cfcfcf;
        margin-bottom: 10px;
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 28px;
        color:#000;
        input{
          width: 42px;
          height: 42px;
          margin-right: 20px;
          border: 2px solid #cfcfcf;
          color:#000;
        }
      }
    }
    .box_3{
      margin-top: 40px;
      .title-box{
        font-size: 28px;
        font-weight: 700;
        color:#000;
        margin-bottom: 15px;
      }
      .input-box{
        display: flex;
        align-items: center;
        input{
          width: 330px;
          height:70px ;
          box-sizing: border-box;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 26px;
          margin-right: 8px;
          border-radius: 20px;
           border: 1px solid #e9e9e9;
        }
      }
    }
    .box_4{
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        margin-top: 28px;
        padding-top: 30px;
        padding-bottom: 30px;
        .title-box{
          font-size: 30px;
          font-weight: 700;
          color:#000;
          margin-bottom: 15px;
        }
        select{
          width: 460px;
          height:70px ;
          box-sizing: border-box;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 26px;
          margin-right: 8px;
          border-radius: 20px;
           border: 1px solid #e9e9e9;
        }
    }
  }
}
.session_3{
  margin-top: 45px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 60px;
  .btn-box{
    height: 90px;
    line-height: 90px;
    text-align: center;
    width: 100%;
    background: #5772e8;
    color:#fff;
    border-radius: 30px;
    font-size: 30px;
  }
  .black-box{
    
    height: 90px;
    line-height: 90px;
    text-align: center;
    width: 100%;
    background: #5772e8;
    color:#fff;
    width: 200px;
    margin:0 auto;
    border-radius: 30px;
    font-size: 30px;
    margin-top: 50px;
  }
}

</style>