<template>
  <div class="page-box">
     <div class="session_1">
        <div class="top-box">
          <div class="back-box">
            <span @click="goto('/cloud')"
              ><img src="@/assets/images/jt.png" alt=""
            /></span>
          </div>
          <span>クラウドマシンを購入する</span>
        </div>
        <div class="list-box">
          <div class="item-box" v-for="(item,index) in list_machine" :key="index">
            <div class="title-box">
              <img src="@/assets/images/icon_27.png" alt="">{{item.name}}<img src="@/assets/images/icon_27.png" alt="">
            </div>
            <dl>
              <dd class="title"><img src="@/assets/images/icon_28.png" alt=""></dd>
              <dd>≈{{item.quantity}}台</dd>
              <dd class="title"><span>価格</span></dd>
              <dd>{{item.price_zh}}</dd>
            </dl>
            <dl>
              <dd class="title"><span>時間</span></dd>
              <dd>{{item.finish_time}}分</dd>
              <dd class="title"><span>収益</span></dd>
              <dd>{{item.income}}%</dd>
            </dl>
            <div class="btn" @click="checknumber(item)">買う</div>
          </div>
        </div>
     </div>
     <van-popup v-model="show" position="bottom" :style="{ height: '25%' }">
      <div class="form-box">
        <div class="input-box">
          <input type="text" v-model="textnumber" placeholder="クラウドマシンコードを入力">
        </div>
        <div class="input-box">
          受付係に連絡し、番号を取得してください。
        </div>
        <div class="btn-box" @click="paynumber">提出の確認</div>
      </div>
     </van-popup>
  </div>
</template>

<script>
import { robotall, robotbuy, cloudnumber, checkyue } from "@/api/robot"
export default {
  name: 'BuyMachine',
  data() {
    return {
        id: 0,
        show: false,
        textnumber: '',
        list_machine:[]
    };
  },

  mounted() {
    this.get_robot_list();
  },

  methods: {
    goto(url) {
      this.$router.push(url);
    },
    checknumber(item){
      if(item.purchase_limit == 1){
        this.payClick(item);
        return false;
      }
      this.id = item.id;
      this.show = true;
    },
    paynumber(){
      let data = {
        id: this.id,
        number: this.textnumber
      };
      cloudnumber(data).then((res) => {
          this.$toast({
            message: "購入が成功しました",
            onClose: ()=>{
              this.$router.push("/cloud");
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    payClick(item){
      let data = {
        id: item.id
      };
      robotbuy(data).then((res) => {
          this.$toast({
            message: "購入が成功しました",
            onClose: ()=>{
              this.$router.push("/cloud");
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_robot_list() {
      var _this = this;
      robotall().then((data) => {
          _this.list_machine = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .page-box{
    min-height: 100vh;
    background: #fff6e0;
  }
  .session_1{
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    padding-top: 24px;
    .top-box {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          height: 40px;
        }
      }
    }
  }
    .item-box{
      position: relative;
      height: 273px;
      background: url(@/assets/images/icon_26.png);
      background-size: 100% 100%;
      padding-top: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      
      .title-box{
        display: flex;
        align-items: center;
        justify-content: center;
        color:#61b774;
        font-weight: 700;
        font-size: 30px;
        img{
          height: 45px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }
      dl{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        padding-right: 80px;
        padding-left: 10px;
        dd{
          text-align: center;
          width: 25%;
          color:#61b774;
          font-size: 26px;
          &.title{
              span{
                display: inline-block;
                background: #fbe6af;
                border-radius: 20px;
                width: 90px;
              }
          }
          img{
            height: 41px;
          }
        }
      }
      .btn{
        position: absolute;
        left: 50%;
        height: 40px;
        line-height: 40px;
        background: #488756;
        display: inline-block;
        width: 200px;
        text-align: center;
        color:#fff;
        font-size: 26px;
        border-radius: 20px;
        transform: translateX(-50%);
        bottom: 20px;
        &::before{
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          background: #fff;
          border-radius: 50%;
          left: 40px;
          top: 50%;
          margin-top: -5px;
        }
        &::after{
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          background: #fff;
          border-radius: 50%;
          right: 40px;
          top: 50%;
          margin-top: -5px;
        }
      }
    }
  }
  .form-box {
    padding: 40px;
  }
  .input-box{
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
    span{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 26px;
    }
    input{
      width: 100%;
      height: 72px;
      line-height: 72px;
      font-size: 26px;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      outline: none;
      border:1px solid #dfdfdf;
      border-radius: 10px;
      
    }
  }
  .btn-box{
    height: 82px;
    line-height: 82px;
    width: 100%;
    background:#4f73ee;
    text-align: center;
    border-radius: 50px;
    color:#fff;
    font-size: 32px;
  }
</style>