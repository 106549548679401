<template>
    <div class="page-box">
        <div class="session_1">
            <div class="box_1-box">
              <div class="back-box">
                  <span @click="goto('/employ')"
                  ><img src="@/assets/images/jt.png" alt=""
                  /></span>
              </div>
              <span>{{ code=='bank' ? '振込先口座情報' : '支払い詳細'}}</span>
            </div>
            <div class="haha"></div>
        </div>
        <div class="session_2" v-if="code == 'paypay'">
          <div class="content">
              <div class="tishi" style="padding: 0 30px;" v-if="status==1">
                <p style="font-weight: bold;">未払い、またはお支払い確認中の支払いがあります</p>
                <p style="font-weight: bold;">【未払いの方】</p>
                <p style="font-weight: bold;">お支払い先、金額を変更したい場合は、支払いのキャンセル後、再度いをお申込みください。</p>
              </div>
              <div class="money">
                <span class="tit">支払い金額：</span>
                <span class="price">{{content.money}}円</span>
              </div>
              <div class="shijian" v-if="status==1 && time > 0">
                <span class="tit">カウントダウン：</span>
                <van-count-down ref="countDown" millisecond :time="time" @finish="finish"></van-count-down>
              </div>
          </div>
          <div class="line"></div>
          <div class="contents" v-if="status==1">
              <div class="tips">PayPayアプリで<br>QRコ一ドをスキャンして支払い</div>
              <div class="ewm" v-if="content.ewm"><img :src="content.ewm.ewm"></div>
              <div class="btns"><van-button type="info" style="width: 300px;" round @click="paylink">支払いリンク</van-button></div>
              <div class="btns"><van-button type="primary" style="width: 300px;" round @click="paycannel">支払いをキャンセル</van-button></div>
          </div>
        </div>
        <div class="session_3" v-if="code == 'bank'">
          <div class="tishi" v-if="status==1">
              <p style="font-weight: bold;">未払い、またはお支払い確認中の支払いがあります</p>
              <p style="font-weight: bold;">【未払いの方】</p>
              <p style="font-weight: bold;">お支払い先、金額を変更したい場合は、支払いのキャンセル後、再度いをお申込みください。</p>
          </div>
          <div class="banks" v-if="status==1">
            <div class="title">振込额(手数料别)</div>
            <div class="cont red">{{content.money}}円</div>
            <div class="title">銀行名</div>
            <div class="cont" v-if="content.dict && content.dict.length > 0">{{content.dict[0]}}</div>
            <div class="title">支店名</div>
            <div class="cont" v-if="content.dict && content.dict.length > 0">{{content.dict[1]}}</div>
            <div class="title">口座種類</div>
            <div class="cont">普通</div>
            <div class="title">口座番号</div>
            <div class="linecol" v-if="content.dict && content.dict.length > 0">{{content.dict[2]}}<br><span>※口座番号はお手続きごとに発行されます。</span></div>
            <div class="title">振込期限</div>
            <div class="cont" v-if="content.yxq">{{content.yxq}}(本日中)</div>
          </div>
          <div class="btns" v-if="status==1" style="display: flex;justify-content: center;padding: 30px 0;"><van-button type="primary" style="width: 300px;margin:0 auto;" round @click="paycannel">支払いをキャンセル</van-button></div>
        </div>
        <div class="contents" v-if="status==2">
          <div class="tips"><img src="@/assets/images/success.png">支払い成功</div>
        </div>
        <div class="contents" v-if="status==3">
          <div class="tips"><img src="@/assets/images/fail.png">チャージに 失敗 しましたので、サポートまでご 連絡 ください。</div>
        </div>
    </div>
</template>
<script>
import { ordershow, getDictData, cancelorder } from "@/api/pay";
import { cutMoneyFiter } from "@/utils/format";
export default {
  name: "Experience",
  data() {
    return {
      order_id: this.$route.query.order_id || 0,
      code: this.$route.query.code || 0,
      status: 1,
      status_txt: '',
      content: {},
      time: 0
    };
  },
  watch: {
    status: {
      handler(val){
        this.status = val;
      },
      deep: true
    }
  },
  mounted() {
    this.orderdetail();
  },

  methods: {
    goto(url) {
      this.$router.push(url);
    },
    paylink(){
      location.href = this.content.ewm.link;
    },
    paycannel(){
      let data = {
        'order_id': this.order_id
      };
      cancelorder(data).then((res) => {
        this.$toast({
          message: "キャンセル成功",
          onClose: ()=>{
            this.$router.push({
              path: "/employ"
            });
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async orderdetail(){
      let param = {
        'code': this.code,
        'order_id': this.order_id
      };
      await ordershow(param).then(res => {
          this.content = res;
          this.content.money = cutMoneyFiter(res.money);
          this.status = res.status;
          if(this.code == 'paypay'){
            if(!res.ewm){
              this.status = 3;
              return false;
            }
            let timestamp = Date.parse(new Date()) / 1000;
            let min = res.chaoshi - timestamp;
            this.time = min >= 0 ? min * 1000 : 0;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    finish() {
      let data = {
        'order_id': this.order_id
      };
      let that = this;
      cancelorder(data).then((res) => {
        that.status = 3;
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .haha {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
  }
  .box_1-box {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    z-index: 9999;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          padding-left: 30px;
          height: 40px;
        }
      }
    }
  }
}
.content{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.6;
  .tips {
      font-size: 26px;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
  }
  .money {
    display: flex;
    align-items: center;
    .tit {
      font-size: 26px;
      padding-right: 20px;
    }
    .price {
      font-size: 50px;
      font-weight: bold;
    }
  }
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
}
.line {
    margin: 50px 0;
    height: 1px;
    border-top: #ececec 1px solid;
    width: 100%;
}
.shijian {
  display: flex;
  align-items: center;
}
.contents{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    line-height: 1.6;
    font-size: 30px;
    .tips {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      line-height: 1.6;
      word-break: break-all;
      align-items: center;
      img {
        width: 160px;
        padding-bottom: 20px;
      }
    }
    .ewm {
      padding-top: 50px;
      img {
        width: 300px;
        height: 300px;
        border: 1px #ccc solid;
        padding: 20px;
      }
    }
    .btns {
      padding-top: 50px;
    }
}
.session_3 {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  box-sizing: border-box;
}
.banks {
  border:2px #dde1e8 solid;
  border-radius: 30px;
  width: 100%;
  overflow: hidden;
  .title {
    background-color: #ebedf2;
    line-height: 3;
    padding-left: 20px;
    font-size: 28px;
  }
  .cont {
    background-color: #fff;
    line-height: 3;
    padding-left: 20px;
    font-size: 26px;
  }
  .red {
    background-color: #ffe0e0 !important;
    border:2px #e8380d solid;
    font-size: 36px;
  }
  .linecol {
    background-color: #fff;
    line-height: 2;
    height: 100px;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    span {
      color: #ee0a24;
      font-size: 22px;
    }
  }
}
.tishi {
  line-height: 1.6;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  font-size: 26px;
}
</style>