<template>
    <div class="employ-box">
      <div class="session_1">
        <div class="top-box">
            <span class="title-box">支払い方法を追加</span>
            <div class="back-box" @click="goto('/selectpay')">
              <span><img src="@/assets/images/jt.png" /></span>
            </div>
        </div>
        <van-tabs v-model="tab_index" color="#5772e8">
          <van-tab title="銀行振り込み">
            <div class="tab-all">
              <div class="list-box">
                <div class="imgbox_4">
                    <div class="imgbox_5">本名</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="truename" class="zz_input" placeholder="本名を入力">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">銀行カード名</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="bank_name" class="zz_input" placeholder="銀行カード名を入力">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">銀行カード番号</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="bank_no" class="zz_input" placeholder="銀行カード番号を入力">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">口座開設銀行コード</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="bank_code" class="zz_input" placeholder="銀行コードを入力">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">口座開設銀行</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="bank_open" class="zz_input" placeholder="口座開設銀行を入力">
                    </div>
                </div>
              </div>
              <div class="btn-box" @click="bankClick">
                提出
              </div>
            </div>
          </van-tab>
          <van-tab title="PAYPAY">
            <div class="tab-all">
              <div class="list-box">
                <div class="imgbox_4">
                    <div class="imgbox_5">PAYPAY 電話番号</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="mobile" class="zz_input" placeholder="電話番号を入力 ">
                    </div>
                </div>
              </div>
              <div class="btn-box" @click="payClick">
                提出
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <Tabbar></Tabbar>
    </div>
  </template>
  
  <script>
  import Tabbar from "@/components/Tabbar.vue";
  import { createbank } from '@/api/pay';
  export default {
    name: "Employ",
    components: {
      Tabbar,
    },
    data() {
      return {
        tab_index: 0,
        mobile: '',
        truename: '',
        bank_name: '',
        bank_no: '',
        bank_code: '',
        bank_open: '',
      };
    },
    mounted(){

    },
    methods: {
      goto(url) {
        this.$router.push(url);
      },
      payClick() {
        let data = {
            'type': 1,
            'mobile': this.mobile
        };
        createbank(data).then((res) => {
            this.$toast({
                message: "正常に追加されました",
                onClose: ()=>{
                    this.$router.push({
                        path: "/selectpay"
                    });
                },
            });
        })
        .catch((err) => {
            console.log(err);
        });
      },
      bankClick() {
        let data = {
            'type': 2,
            'truename': this.truename,
            'bank_name': this.bank_name,
            'bank_no': this.bank_no,
            'bank_code': this.bank_code,
            'bank_open': this.bank_open
        };
        createbank(data).then((res) => {
            this.$toast({
                message: "正常に追加されました",
                onClose: ()=>{
                    this.$router.push({
                        path: "/selectpay"
                    });
                },
            });
        })
        .catch((err) => {
            console.log(err);
        });
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .employ-box {
    padding-bottom: 150px;
  }
  .session_1 {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 45px;
    .top-box {
      position: relative;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #000;
      margin-bottom: 30px;
      .back-box {
        position: absolute;
        left: 0;
        top: 0;
        span {
          display: inline-block;
          img {
            height: 50px;
          }
        }
      }
    }
    .tab-all{
      margin-top: 20px;
    }
    .list-box {
      display: flex;
      flex-wrap: wrap;
        .imgbox_4 {
          width: 100%;
          .imgbox_5 {
              width: 100%;
              height: 80px;
              line-height: 80px;
              text-align: left;
              font-size: 28px;
          }
          .zz_inputbox {
            width: 100%;
            height: 80px;
            line-height: 80px;
            .zz_input {
                height: 80px;
                line-height: 80px;
                font-size: 26px;
                width: 100%;
                outline: none;
                border:1px solid #dfdfdf;
                border-radius: 10px;
                padding-left: 30px;
                padding-right: 30px;
                box-sizing: border-box;
            }
          }
        }
    }
    .btn-box {
      width: 100%;
      height: 100px;
      line-height: 100px;
      text-align: center;
      background: #5772e8;
      color: #fff;
      border-radius: 10px;
      font-size: 32px;
      margin-top: 18px;
      font-weight: 700;
    }
  }
  </style>
  