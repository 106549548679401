<template>
  <van-tabbar route>
      <van-tabbar-item replace v-for="(item, index) in tabsList" :key="index" :to="item.path">
          <span>{{ item.title }}</span>
          <template #icon="props">
            <img :src="props.active ? item.img_active : item.img" />
          </template>
      </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      tabsList: [
        {
          title: "ためる",
          path: "/",
          img: require("../assets/images/tabbar/icon_1.png"),
          img_active: require("../assets/images/tabbar/icon_1_1.png"),
        },
        {
          title: "チラシ",
          path: "/Flyer",
          img: require("../assets/images/tabbar/icon_3.png"),
          img_active: require("../assets/images/tabbar/icon_3_1.png"),
        },
        {
          title: "ウオレット",
          path: "/employ",
          img: require("../assets/images/tabbar/icon_4.png"),
          img_active: require("../assets/images/tabbar/icon_4_1.png"),
        },
        {
          title: "マイペ一ジ",
          path: "/my",
          img: require("../assets/images/tabbar/icon_5.png"),
          img_active: require("../assets/images/tabbar/icon_5_1.png"),
        },
      ],
     
    };
  },
};
</script>
<style scoped lang="scss">

</style>
