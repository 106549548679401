<template>
  <div :class="['page-box']">
    <!-- 没有矿机 -->
    <div class="no-machine">
      <div class="session_1">
        <div class="list-box">
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
          <div class="img-box">
            <img src="@/assets/images/icon_23.gif" alt="" />
          </div>
        </div>
      </div>
      <div class="session_2">
        <div class="img-box">
          <img src="@/assets/images/icon_25.png" alt="" />
        </div>
      </div>
      <div class="session_3">
        <div class="des-box">
          <h3>
            1日に5回、収益チャンスを獲得できます<img src="@/assets/images/icon_23.png" alt="" />
          </h3>
          <p>仮想マシンはタスクを自動的に完了します</p>
          <p>
            1日の最大利益は300万円になります<img
              src="@/assets/images/icon_24.png"
              alt=""
            />
          </p>
        </div>
        <div class="btn-box" @click="goto('/Cloud')">
          マイエリアの設定へ進む
        </div>
      </div>
    </div>
    <Tabbar></Tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/Tabbar.vue";
export default {
  name: "Flyer",
  components: {
    Tabbar,
  },
  data() {
    return {

    };
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.page-box {
  padding-bottom: 150px;
  &.active {
    height: 100vh;
    box-sizing: border-box;
  }
}
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  padding-top: 45px;
  .list-box {
    display: flex;
    flex-wrap: wrap;
    .img-box {
      width: 20%;
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
}
.session_2 {
  text-align: center;
  img {
    width: 70%;
  }
}
.session_3 {
  margin-top: 50px;
  .des-box {
    text-align: center;
    h3 {
      text-align: center;
      font-size: 32px;
      color: #000;
      display: flex;
      justify-content: center;
      line-height: normal;
      align-items: center;
      margin-bottom: 45px;

      img {
        height: 40px;
        margin-left: 5px;
      }
    }
    p {
      color: #000;
      font-size: 28px;
      img {
        height: 41px;
        margin-left: 5px;
      }
    }
  }
  .btn-box {
    height: 92px;
    line-height: 92px;
    text-align: center;
    background: #5772e8;
    width: 380px;
    color: #fff;
    margin: 0 auto;
    font-weight: 700;
    font-size: 28px;
    border-radius: 50px;
    margin-top: 50px;
  }
}
.have-machine {
  height: calc(100vh - 100px);
  background: url(@/assets/images/img_7.jpg) center center no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
   .box_1{
    position: absolute;
    right: 50px;
    top: 50px;
    .nub-box {
      display: flex;
      align-items: center;
      height: 78px;
      line-height: 78px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      border-radius: 50px;
      font-size: 28px;
      font-weight: 700;
      color: #000;
      img {
        height: 50px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .btn{
      display: inline-block;
      height: 46px;
      line-height: 46px;
      background: #fc5d66;
      color:#fff;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 30px;
      font-size: 24px ;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .box_2 {
    display: flex;
    padding-left: 48px;
    padding-top: 66px;
    box-sizing: border-box;
    align-items: flex-end;
    .left-box {
      margin-right: 38px;
      img {
        width: 170px;
      }
    }
    .right-box {
      color: #fc5d66;
      font-size: 28px;
       i{
        font-style: normal;
      }
      span {
        font-size: 28px;
        display: inline-block;
        margin-right: 30px;
        &.two {
          letter-spacing: 2em;
          margin-right: -1em;
        }
      }
      .txt_1 {
        img {
          position: relative;
          top: -2px;
          height: 38px;
          margin-left: 10px;
        }
      }
      .btn-box {
        background: #f7adac;
        color: #fff;
        width: 360px;
        text-align: center;
        border-radius: 30px;
        margin-top: 20px;
      }
    }
  }
  .box_3 {
    margin-top: 25px;
    text-align: center;
    .centent-box {
      display: inline-block;
      width: 612px;
      height: 30px;
      background: #e8e8e8;
      border-radius: 15px;
      position: relative;

      span {
        position: absolute;
        left: 0;
        top: 1px;
        background: url(@/assets/images/icon_31.png);
        height: 28px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
     
      em {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 0;
        width: 54px;
        height: 54px;
        line-height: 53px;
        text-align: center;
        background: url(@/assets/images/icon_30.png) center center no-repeat;
        background-size: 100% 100%;
        font-size: 24px;
        color: #fff;
        letter-spacing: -2px;
      }
    }
  }
  .box_4 {
    padding-left: 36px;
    padding-right: 40px;
    box-sizing: border-box;
    margin-top: 12px;
    .centent-box {
      display: flex;
      border-top: 16px solid rgba(247, 173, 172, 0.5);
      border-bottom: 16px solid rgba(247, 173, 172, 0.5);
      .item_1 {
        width: 50%;
        box-sizing: border-box;
        font-size: 28px;
        color: #fc5d66;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        span {
          display: block;
          line-height: 1.2;
        }
        em{
          font-size: 30px;
        }
        &:nth-child(2) {
          border-left: 16px solid rgba(247, 173, 172, 0.5);
        }
      }
    }
    .item_1 {
      width: 50%;
    }
  }
  .box_5{
    padding-left: 30px;
    padding-right: 30px;
    ul{
      padding: 20px;
      width: 100%;
      height: 50vh;
      box-sizing: border-box;
      overflow-Y: scroll;
      li{
        line-height: 1.8;
        margin-bottom: 10px;
        font-size: 26px;
        color:#fc5d66;
      }
    }
  }
 
}
</style>
