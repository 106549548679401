import Cookies from 'js-cookie';

const TokenKey = 'access_token'
const User = 'user'
const Pass = 'pass'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function setUser (user) {
  return Cookies.set(User, user)
}

export function getUser () {
  return Cookies.get(User)
}

export function removeUser () {
  return Cookies.remove(User)
}

export function setPass (user) {
  return Cookies.set(Pass, user)
}

export function getPass () {
  return Cookies.get(Pass)
}

export function removePass () {
  return Cookies.remove(Pass)
}
