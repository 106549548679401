<template>
  <div class="page-box">
    <div class="session_1">
      <div class="box_1-box">
        <div class="back-box">
          <span @click="gotos('/my')"
            ><img src="@/assets/images/icon_35.png" alt=""
          /></span>
        </div>
        <span>レシチャレ参加履歷</span>
      </div>
      <div class="haha"></div>
      <van-tabs sticky :offset-top="1.33333+'rem'" v-model="tab_index" color="#5772e8" @change="changeTab">
        <van-tab title="タスクを受け取りました" name="1">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="これ以上のタスクはありません"
            loading-text="読込中..."
            @load="get_task_list(1)"
          >
            <div class="item-box" v-for="(item, index) in list" :key="index" @click="goto(item)">
              <div class="img-box">
                <img :src="item.tasks.picurl" alt="" />
              </div>
              <div class="txt-box">
                <div class="top-box">
                  <h3>{{ item.tasks.title }}</h3>
                  <p>
                    {{ item.tasks.desc }}
                  </p>
                </div>
                <div class="bottom-box">
                  <div class="left-box">
                    <em>></em><span class="monery">{{ item.tasks.price }}</span
                    >コイン
                  </div>
                  <div class="right-box">
                    <span>進行中</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="タスクが完了しました" name="2">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="これ以上のタスクはありません"
            loading-text="読込中..."
            @load="get_task_list(2)"
          >
            <div class="item-box" v-for="(item, index) in list" :key="index" @click="goto(item)">
              <div class="img-box">
                <img :src="item.tasks.picurl" alt="" />
              </div>
              <div class="txt-box">
                <div class="top-box">
                  <h3>{{ item.tasks.title }}</h3>
                  <p>
                    {{ item.tasks.desc }}
                  </p>
                </div>
                <div class="bottom-box">
                  <div class="left-box">
                    <em>></em><span class="monery">{{ item.tasks.price }}</span
                    >コイン
                  </div>
                  <div class="right-box">
                    <span>完成しまし</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { mytasklist } from "@/api/task";
export default {
  name: "Experience",
  data() {
    return {
      tab_index: 0,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10
    };
  },

  mounted() {},

  methods: {
    gotos(url) {
      this.$router.push(url);
    },
    goto(item) {
      this.$router.push({
        path: "/ProDetail",
        query: {
          id: item.task_id,
        },
      });
    },
    changeTab(name) {
      this.page = 1;
      this.list = [];
      this.get_task_list(name);
    },
    get_task_list(status) {
      var _this = this;
      var params = {
        status: status,
        page_no: this.page,
        page_size: this.limit,
      };
      mytasklist(params)
        .then((data) => {
          _this.list = _this.list.concat(data.lists);
          this.loading = false;
          if (_this.list.length == data.count) {
            _this.finished = true;
          }
          _this.page++;
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .haha {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
  }
  .box_1-box {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    z-index: 9999;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          padding-left: 30px;
          height: 40px;
        }
      }
    }
  }
  .item-box {
    border-bottom: 1px solid #ddddde;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    line-height: 1.6;
    .img-box {
      margin-right: 30px;
      img {
        width: 172px;
        height: 172px;
        object-fit: cover;
      }
    }
    .txt-box {
      width: 470px;
      box-sizing: border-box;
      .top-box {
        h3 {
          font-size: 28px;
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-size: 26px;
          line-height: 1.6;
        }
      }
      .bottom-box {
        font-weight: 700;
        color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-box {
          .no {
            color: #de6043;
            text-decoration: line-through;
            font-weight: 700;
          }
          em {
            font-weight: 700;
            color: #000;
            margin-left: 10px;
            margin-right: 10px;
          }
          .monery {
            color: #de6043;
            font-size: 32px;
            font-weight: 700;
            margin-right: 10px;
          }
        }
        .right-box {
          span{
            color:#fff;
            font-weight: 400;
            padding-left: 10px;
            padding-right: 10px;
            background: #de6043;
            border-radius: 20px;
             line-height: normal;
             display: flex;
             align-items: center;
             height: 38px;
            
          }
        }
      }
    }
  }
}
</style>
