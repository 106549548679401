import request from '@/utils/request'

export function homephoto() {
  return request({
    url: '/api/index/photo',
    method: 'GET'
  })
}

export function paypaylist() {
  return request({
    url: '/api/index/paypaylist',
    method: 'GET'
  })
}

export function mypayorder() {
  return request({
    url: '/api/index/payorder',
    method: 'GET'
  })
}