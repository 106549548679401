<template>
  <div class="page-box">
    <div class="session_1">
      <div class="top-box">
        <div class="back-box">
          <span @click="goto('/my')"
            ><img src="@/assets/images/icon_35.png" alt=""
          /></span>
        </div>
        <span>>アカウント情報</span>
      </div>
      <div class="form-box">
        <div class="input-box two-box">
          <span>お名前</span>
          <input type="text" v-model="userinfo.lastname" placeholder="名" />
          <input type="text" v-model="userinfo.firstname" placeholder="姓" />
        </div>
        <div class="input-box two-box">
          <span>携帯電話</span>
          <select v-model="userinfo.area">
            <option value="081">Japan(+81)</option>
          </select>
          <input type="text" v-model="userinfo.mobile" placeholder="電話番号を入力" />
        </div>
        <div class="input-box">
          <span>メ一ル</span>
          <input type="text" v-model="userinfo.email" placeholder="メールアドレス" />
        </div>
        <div class="input-box" v-if="is_show==false">
          <span>パスワード</span>
          <input type="password" v-model="userinfo.money_password" placeholder="パスワード" />
        </div>
        <div class="btn-box" @click="submit">会員情報の修正</div>
      </div>
    </div>
    <div class="session_2">
      <div class="txt_1" @click="logouts">ログアウト</div>
    </div>
  </div>
</template>

<script>
import { editUser, logout, getUserCenter } from '@/api/user'
export default {
  name: "Information",
  data() {
    return {
      is_show: false,
      userinfo: {
        lastname: '',
        firstname: '',
        area: '081',
        mobile: '',
        email: ''
      },
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      getUserCenter()
        .then(data => {
          this.userinfo.lastname = data.lastname;
          this.userinfo.firstname = data.firstname;
          this.userinfo.area = data.area;
          this.userinfo.mobile = data.mobile;
          this.userinfo.email = data.email;
          this.userinfo.money_password = data.money_password;
          if(data.money_password){
            this.is_show = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    goto(url) {
      this.$router.push(url);
    },
    submit(){
      editUser(this.userinfo)
        .then(response => {
          this.$toast({
            message: "アップデート成功",
            onClose: ()=>{
              this.$router.push('/my');
            },
          });
        })
        .catch(err => {
          console.log(err);
        })
    },
    logouts(){
      logout()
        .then(response => {
          this.$store.dispatch("logout");
          this.$toast({
            message: "退出成功",
            onClose: ()=>{
              this.$router.push("/my");
            },
          });
        })
        .catch(err=>{
          console.log(err);
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .top-box {
    position: relative;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          height: 40px;
        }
      }
    }
  }
  .title-box{
    font-size: 38px;
    color:#000;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .input-box {
    margin-bottom: 30px;
    position: relative;
    padding-left: 120px;
    box-sizing: border-box;
    span {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 26px;
      width: 125px;
    }
    input,
    select {
      width: 100%;
      height: 72px;
      line-height: 72px;
      font-size: 26px;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      outline: none;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
    }
    &.two-box {
      input,
      select {
        width: 48%;
        &:nth-child(2) {
          margin-right: 4%;
        }
      }
    }
  }
  .btn-box {
    height: 82px;
    line-height: 82px;
    width: 100%;
    background: #4f73ee;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    font-size: 32px;
  }
}
.session_2{
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 60px;
  .txt_1{
    font-size: 26px;
    color:#000;
    margin-bottom: 20px;
  }
  .txt_2{
     font-size: 26px;
     color:red;
  }
}
</style>
