<template>
  <div :class="['page-box', 'active']">
    <div class="have-machine">
      <div class="box_1">
        <p class="nub-box"><img src="@/assets/images/icon_9_12.png" alt="" />{{ robot_money }}<span @click="movemoney" class="btn">資金を転移する</span></p>
      </div>
      <div class="box_2">
        <div class="left-box">
          <img src="@/assets/images/icon_29.png" alt="" />
        </div>
        <div class="right-box">
          <div class="txt_1">
            <span class="two">数量</span
            ><em>{{machine_nub}}台<img src="@/assets/images/icon_28_1.png" alt="" /></em>
          </div>
          <div class="txt_2">
            <span class="title">運行状態</span><em><i v-if="progress==100">完成しまし</i><i v-else-if="progress<100 && progress>0">運行中</i><i v-else>しばらく</i></em>
          </div>
          <!-- 当精度条为100说明完成 显示续费 -->
          <div class="btn-boxs" @click="goto('buyMachine')">クラウドマシンを購入する</div>
        </div>
      </div>
      <div class="box_3">
        <div class="centent-box">
          <span :style="{ width: progress + '%' }"></span>
          <em :style="{ left: progress + '%' }">{{ progress }}%</em>
        </div>
      </div>
      <div class="box_4">
        <div class="centent-box">
          <div class="item_1">
            <span>{{ months_robot_money }}</span>
            <em>今月の獲得コイン</em>
          </div>
          <div class="item_1">
            <span>{{ total_money }}</span>
            <em>獲得予定コイン</em>
          </div>
        </div>
      </div>
      <div class="box_5">
        <ul>
          <li v-for="item in tasklist">{{ item }}</li>
        </ul>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '25%' }">
      <div class="form-box">
        <div class="input-box" style="font-weight: bold;">
          【コイン】に移行したい金額をご入力してください：
        </div>
        <div class="input-box">
          <input type="number" v-model="zyje">
        </div>
        <div class="btn-box" @click="zhuanyi">確認</div>
      </div>
    </van-popup>
    <Tabbar></Tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { baseURL } from '@/utils/request'
import { getToken } from "@/utils/auth";
import { myrobot, allincome, zhuanyimoney } from "@/api/robot"
import Tabbar from "@/components/Tabbar.vue";
import { cutMoneyFiter } from "@/utils/format";
export default {
  name: "Flyer",
  components: {
    Tabbar,
  },
  data() {
    return {
      show: false,
      zyje: '',
      machine_nub: 0,
      machine_list: [],
      robot_money: 0,
      robot_moneys: 0,
      move_money: 150000,
      total_money: 0,
      months_robot_money: 0,
      beginTime: 0, //时间戳
      finishTime: 0, //时间戳
      progress: 0,
      timer: null, //定义计算器
      charu: null,
      tasklist: [],
      logs: [
          '空き設備 タスク取得中。。',
          'タスクを正常に取得しました',
          '運行中。 。 。',
          '合計 ** 個のビデオタスクが完了しました',
          'クールダウンタイムをお待ちください',
          'クールダウンタイムが終了しました',
          'デバッグボックスをリセット'
      ]
    };
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    SetProgress() {
      if(this.beginTime > 0){
          const time = (this.getLocalTime(8) - this.beginTime) / 1000 / 60;
          const progress = time > this.finishTime ? 100 : Math.round(time / this.finishTime * 100);
          this.progress = progress < 1 ? 1 : progress;
      }
    },
    movemoney(){
      this.show = true;
    },
    zhuanyi(){
      let data = {
        money: this.zyje
      };
      zhuanyimoney(data).then((res) => {
          this.$toast({
            message: "払い込みは成功しました"
          });
          this.show = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_myrobot() {
      let _this = this;
      myrobot().then((data) => {
          _this.machine_list = data.list;
          _this.beginTime = data.beginTime;
          _this.finishTime = data.finishTime;
          _this.robot_moneys = data.robot_money;
          _this.robot_money = cutMoneyFiter(data.robot_money);
          _this.move_money = data.move_money;
          _this.total_money = cutMoneyFiter(data.total_money);
          _this.months_robot_money = cutMoneyFiter(data.months_robot_money);
          data.list.map((item)=>{
            _this.machine_nub = item.robot.quantity;
          });
          _this.tipstext();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getallincome(){
      let _this = this;
      axios({
          method: 'get',
          url: baseURL + '/api/robot/myrobot',
          headers: {'token': getToken()},
      })
      .then(function (res) {
          let data = res.data.data;
          _this.beginTime = data.beginTime;
          _this.robot_moneys = data.robot_money;
          _this.robot_money = cutMoneyFiter(data.robot_money);
          _this.move_money = data.move_money;
          _this.months_robot_money = cutMoneyFiter(data.months_robot_money);
      });
    },
    tipstext(){
      let arr = this.logs;
      let that = this;
      if(this.beginTime > 0){
          (function(arr){
              let i=1,length=arr.length;
              (function(){
                  that.tasklist.push(arr[0]);
                  that.charu = setInterval(function(){
                      if(i <= length) {
                          that.tasklist.push(arr[i++]);
                      }
                      if(that.beginTime === 0 || that.progress === 100){
                          that.tasklist = [];
                          clearInterval(that.charu);
                      }
                      if(i > length){
                          clearInterval(that.charu);
                          setTimeout(()=>{
                              that.tasklist = [];
                              that.tipstext();
                          },1000*10);
                      }
                  },1000*10);
              }());
          }(arr));
      }
    },
    getLocalTime(i) { 
      if (typeof i !== 'number') return;
      var d = new Date(); 
        //得到1970年一月一日到现在的秒数 
        var len = d.getTime();
        //本地时间与GMT时间的时间偏移差(注意：GMT这是UTC的民间名称。GMT=UTC）
        var offset = d.getTimezoneOffset() * 60000;
        //得到现在的格林尼治时间
        var utcTime = len + offset;
        //return new Date(utcTime + 3600000 * i);日期格式
        return utcTime + 3600000 * i;//时间戳
    }
  },
  mounted() {
      this.get_myrobot();
      this.SetProgress(); //第一次加载的时候快
      this.timer = setInterval(() => {
          this.SetProgress();
          this.getallincome();
      }, 1000);
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="scss">
.page-box {
  padding-bottom: 150px;
  &.active {
    height: 100vh;
    box-sizing: border-box;
  }
}
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  padding-top: 45px;
  .list-box {
    display: flex;
    flex-wrap: wrap;
    .img-box {
      width: 20%;
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
}
.session_2 {
  text-align: center;
  img {
    width: 70%;
  }
}
.session_3 {
  margin-top: 50px;
  .des-box {
    text-align: center;
    h3 {
      text-align: center;
      font-size: 32px;
      color: #000;
      display: flex;
      justify-content: center;
      line-height: normal;
      align-items: center;
      margin-bottom: 45px;

      img {
        height: 40px;
        margin-left: 5px;
      }
    }
    p {
      color: #000;
      font-size: 28px;
      img {
        height: 41px;
        margin-left: 5px;
      }
    }
  }
  .btn-box {
    height: 92px;
    line-height: 92px;
    text-align: center;
    background: #5772e8;
    width: 380px;
    color: #fff;
    margin: 0 auto;
    font-weight: 700;
    font-size: 28px;
    border-radius: 50px;
    margin-top: 50px;
  }
}
.have-machine {
  height: calc(100vh - 100px);
  background: url(@/assets/images/img_7.jpg) center center no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
   .box_1{
    position: absolute;
    right: 50px;
    top: 50px;
    .nub-box {
      display: flex;
      align-items: center;
      height: 78px;
      line-height: 78px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      border-radius: 50px;
      font-size: 28px;
      font-weight: 700;
      color: #000;
      img {
        height: 50px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .btn{
      display: inline-block;
      height: 46px;
      line-height: 46px;
      background: #fc5d66;
      color:#fff;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 30px;
      font-size: 24px ;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .box_2 {
    display: flex;
    padding-left: 48px;
    padding-top: 66px;
    box-sizing: border-box;
    align-items: flex-end;
    .left-box {
      margin-right: 38px;
      img {
        width: 170px;
      }
    }
    .right-box {
      color: #fc5d66;
      font-size: 28px;
       i{
        font-style: normal;
      }
      span {
        font-size: 28px;
        display: inline-block;
        margin-right: 30px;
        &.two {
          letter-spacing: 2em;
          margin-right: -1em;
        }
      }
      .txt_1 {
        img {
          position: relative;
          top: -2px;
          height: 38px;
          margin-left: 10px;
        }
      }
      .btn-boxs {
        background: #f7adac;
        color: #fff;
        width: 360px;
        text-align: center;
        border-radius: 30px;
        margin-top: 20px;
      }
    }
  }
  .box_3 {
    margin-top: 25px;
    text-align: center;
    .centent-box {
      display: inline-block;
      width: 612px;
      height: 30px;
      background: #e8e8e8;
      border-radius: 15px;
      position: relative;

      span {
        position: absolute;
        left: 0;
        top: 1px;
        background: url(@/assets/images/icon_31.png);
        height: 28px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
     
      em {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 0;
        width: 54px;
        height: 54px;
        line-height: 53px;
        text-align: center;
        background: url(@/assets/images/icon_30.png) center center no-repeat;
        background-size: 100% 100%;
        font-size: 24px;
        color: #fff;
        letter-spacing: -2px;
      }
    }
  }
  .box_4 {
    padding-left: 36px;
    padding-right: 40px;
    box-sizing: border-box;
    margin-top: 12px;
    .centent-box {
      display: flex;
      border-top: 16px solid rgba(247, 173, 172, 0.5);
      border-bottom: 16px solid rgba(247, 173, 172, 0.5);
      .item_1 {
        width: 50%;
        box-sizing: border-box;
        font-size: 28px;
        color: #fc5d66;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        span {
          display: block;
          line-height: 1.2;
        }
        em{
          font-size: 30px;
        }
        &:nth-child(2) {
          border-left: 16px solid rgba(247, 173, 172, 0.5);
        }
      }
    }
    .item_1 {
      width: 50%;
    }
  }
  .box_5{
    padding-left: 30px;
    padding-right: 30px;
    ul{
      padding: 20px;
      width: 100%;
      height: 50vh;
      box-sizing: border-box;
      overflow-Y: scroll;
      li{
        line-height: 1.8;
        margin-bottom: 10px;
        font-size: 26px;
        color:#fc5d66;
      }
    }
  }
 
}
.form-box {
  padding: 40px;
}
.input-box{
  margin-bottom: 30px;
  position: relative;
  box-sizing: border-box;
  span{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 26px;
  }
  input{
    width: 100%;
    height: 72px;
    line-height: 72px;
    font-size: 26px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    outline: none;
    border:1px solid #dfdfdf;
    border-radius: 10px;
    
  }
}
.btn-box{
  height: 82px;
  line-height: 82px;
  width: 100%;
  background:#4f73ee;
  text-align: center;
  border-radius: 50px;
  color:#fff;
  font-size: 32px;
}
</style>