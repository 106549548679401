import { render, staticRenderFns } from "./Addbank.vue?vue&type=template&id=dd6d56fc&scoped=true"
import script from "./Addbank.vue?vue&type=script&lang=js"
export * from "./Addbank.vue?vue&type=script&lang=js"
import style0 from "./Addbank.vue?vue&type=style&index=0&id=dd6d56fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd6d56fc",
  null
  
)

export default component.exports