<template>
    <div class="kefu" @click="kefu"><van-icon name="service-o" color="#1989fa" size="30" /></div>
</template>

<script>
export default {
  name: "Kefu",
  data() {
    return {
      kefuurl: ''
    };
  },
  methods: {
    kefu(){
        if(this.kefuurl){
            location.href = this.kefuurl;
        }
    }
  }
}
</script>
<style scoped lang="scss">
.kefu {
    position: fixed;
    right: 10px;
    bottom: 350px;
    background-color: #fff;
    border: 1px #f2f2f2 solid;
    border-radius: 100%;
    box-shadow: 0 0 10px 2px #bdcee0;
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>