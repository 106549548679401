import request from '@/utils/request'

export function createorder(data) {
    return request({
      url: '/api/pay/payorder',
      method: 'POST',
      data
    })
}

export function ordershow(params) {
    return request({
        url: '/api/pay/orderdetail',
        method: 'GET',
        params
    })
}

//字典数据
export function getDictData(params) {
    return request({
        url: '/api/pay/dict',
        method: 'GET',
        params
    })
}

export function createbank(data) {
    return request({
      url: '/api/tixian/bankpay',
      method: 'POST',
      data
    })
}

export function banklist() {
    return request({
        url: '/api/tixian/banklist',
        method: 'GET'
    })
}

export function createtxorder(data) {
    return request({
      url: '/api/tixian/txorder',
      method: 'POST',
      data
    })
}

export function cancelorder(data) {
    return request({
      url: '/api/pay/cancel',
      method: 'POST',
      data
    })
}

export function rechargeList(params) {
    return request({
        url: '/api/pay/recharge',
        method: 'GET',
        params
    })
}

export function withdrawList(params) {
    return request({
        url: '/api/pay/withdraw',
        method: 'GET',
        params
    })
}

export function rechargedetail(params) {
    return request({
        url: '/api/pay/detail',
        method: 'GET',
        params
    })
}

export function withdrawdetail(params) {
    return request({
        url: '/api/tixian/detail',
        method: 'GET',
        params
    })
}