<template>
  <div>
    <PayNoProcess ref="payno"></PayNoProcess>
    <div class="session_3">
      <div class="all-box">
        <ul>
          <li v-for="(item, index) in chance_list" :key="index">
            <div class="icon-box">
              <img src="@/assets/images/icon_6.png" alt="" />
            </div>
            <div class="bar"><em :style="{ width: item + '%' }"></em></div>
            <p>{{ item }}%</p>
          </li>
        </ul>
      </div>
      <div class="box-box">
        <div
          :class="['btn-box', is_chance ? 'active' : '']"
          @click="showPopup"
        >
          <img src="@/assets/images/icon_9.png" alt="" />
          <img src="@/assets/images/icon_9_1.png" alt="" class="active" />ゲット
        </div>
      </div>
    </div>
    <!-- 视频弹框 playing-->
    <div :class="['session_8', playing ? 'active' : '']">
      <div class="box_1">
        <div class="btn-box">
          <span class="txt_1" v-show="this.play_time > 0"
            ><img src="@/assets/images/icon_9_1.png" alt="" />{{
              this.play_time
            }}秒後に報酬を受け取れます</span
          >
          <span class="txt_2" v-show="this.play_time == 0"
            ><img src="@/assets/images/icon_9_1.png" alt="" />受領済<img
              src="@/assets/images/icon_22.png"
              alt=""
              class="close-btn"
              @click="close_btn"
          /></span>
        </div>
      </div>
      <div class="video-box" v-if="videoUrl">
        <video :src="videoUrl" webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page"></video>
      </div>
      <div class="box_2">
        <div v-if="video_picurl" class="img-box">
          <img :src="video_picurl" :alt="video_title" />
        </div>
        <div class="bottom-box">
          <h3 v-if="video_title">{{ video_title }}</h3>
          <p v-if="video_desc">{{ video_desc }}</p>
          <a v-if="video_downurl" :href="video_downurl"><div class="btn-box">ダンウロード</div></a>
        </div>
      </div>
    </div>
    <van-popup
      v-model="show_pop"
      :close-on-click-overlay="false"
      round
      position="bottom"
      class="pop-box"
    >
      <div class="centent-box">
        <div class="title-box">
          <div class="icon-box">
            <img src="@/assets/images/icon_32.png" alt="" />
          </div>
          <p>コインをゲット</p>
        </div>
        <div class="body-box">
          <span @click="chanceClick">CMを見て60コインをゲット</span>
          <div class="black-box" @click="closePopup">キャンセル</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { taskvideo, receivevideo, ischance } from "@/api/task";
import PayNoProcess from "@/components/PayNoProcess.vue";
export default {
  name: "DrawHome",
  components: {
    PayNoProcess
  },
  data() {
    return {
      is_login: this.$store.state.isLogin,
      chance_list: [0, 0, 0, 0, 0],
      time_draw: 20, //1表示一分钟
      is_chance: false,
      show_pop: false,
      // 视频
      videoUrl: '',
      playing: false,
      play_time: 0,
      timer: null,
      video_id: 0,
      video_title: '',
      video_picurl: '',
      video_desc: '',
      video_downurl: '',
      price: 0
    };
  },
  watch: {
    playing: {
      handler(val){
        if(val == true){
          this.$refs.payno.qingchu();
        }else{
          this.$refs.payno.start();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.first_list(); //刷新页面跟第一次进来的时候数据渲染的快
    this.timer = setInterval(() => {
      this.first_list();
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
  },
  methods: {
    async getvideo(){
      let that = this;
      await taskvideo()
        .then((data) => {
          that.videoUrl = data.videourl;
          that.play_time = data.second;
          that.video_id = data.id;
          that.video_title = data.title;
          that.video_picurl = data.picurl;
          that.video_desc = data.desc;
          that.video_downurl = data.downurl;
          that.price = data.price;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jianli(video_id){
      receivevideo({
        video_id: video_id
      })
      .then((res) => {
        console.log("已领取奖励");
      })
      .catch((err) => {
        console.log(err);
      });
    },
    isChance() {
      this.is_chance = this.chance_list.some((item) => {
        return item == 100;
      });
    },
    async chanceClick() {
      await this.getvideo();
      this.show_pop = false;
      var index_nub = -1;
      this.chance_list.filter((item, index) => {
        if (item == 100) {
          index_nub = index;
        }
      });
      this.setTime(index_nub);
      // 视频播放
      if(this.videoUrl){
        this.jianli(this.video_id);
        await this.video();
      }
    },
    first_list() {
      if (!localStorage.getItem("chance_list")) {
        this.chance_list = [100, 100, 100, 100, 100];
      } else {
        this.chance_list = localStorage.getItem("chance_list").split(",");
        var time = this.time_draw;
        let chance_max=-1 ;
        for(let i =this.chance_list.length-1; i>=0;i--){
          if(0<this.chance_list[i] && this.chance_list[i]!=100){
              chance_max=i
              break;
          }
        }
        if(chance_max==-1){
          for(let i =this.chance_list.length-1; i>=0;i--){
            if(this.chance_list[i]<100){
              chance_max=i
                break;
            }
          }
        }
        if (localStorage.getItem("time")&&chance_max>-1) {
          const nub = Math.abs(new Date(localStorage.getItem("time")) - new Date()) / 1000; /* 得到的是秒 */
          if (nub >= 60 * time) {
            this.chance_list.splice(chance_max, 1, 100);
            localStorage.setItem("time", new Date());
          } else {
            this.chance_list.splice(
              chance_max,
              1,
              Math.round((100 / 60 / time) * nub)
            );
          }
          localStorage.setItem("chance_list", this.chance_list);
        }
      }
      this.isChance();
    },
    setTime(index) {
      this.chance_list.splice(index, 1, 0);
      localStorage.setItem("chance_list", this.chance_list);
      if (!localStorage.getItem("time")) {
        localStorage.setItem("time", new Date());
      }
      this.isChance();
    },
    close_btn() {
      if(this.videoUrl){
        this.video();
      }
      this.play_time = 0;
    },
    video() {
      this.playing = !this.playing;
      const video = this.$el.querySelector("video");
      if (this.playing) {
        video.play();
        var timerId = setInterval(() => {
          this.play_time -= 1;
          if (this.play_time == 0) {
            console.log("结束了");
            clearInterval(timerId);
          }
        }, 1000);
      } else {
        video.pause();
      }
    },
    showPopup() {
      if(!this.is_login){
        this.$toast({
          message: "未登録!",
          onClose: ()=>{
            this.$router.push('/my');
          },
        });
        return false;
      }
      let that = this;
      ischance().then((res) => {
        if (!res.is_chance) {
          that.$toast("動画の視聴を続けて収益を得る前に、【クラウドマシンを】を完了してください");
          return false;
        }
        that.show_pop = true;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    closePopup() {
      this.show_pop = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.session_3 {
  background: url(@/assets/images/img_1.jpg) top center no-repeat;
  background-size: 100% 350px;
  min-height: 350px;
  padding-top: 134px;
  overflow-y: hidden;
  box-sizing: border-box;
  .all-box {
    overflow: scroll;
  }
  ul {
    position: relative;
    display: flex;
    padding-left: 175px;
    margin-left: 30px;

    &::before {
      position: absolute;
      left: 0;
      content: "";
      top: 0;
      width: 144px;
      height: 230px;
      background: url(@/assets/images/icon_8.png);
      background-size: 100% 100%;
    }
    li {
      margin-right: 34px;
      .icon-box {
        img {
          height: 216px;
        }
      }
      .bar {
        display: inline-block;
        width: 120px;
        height: 20px;
        background: #f0f0f0;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
        em {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 0%;
          background: #f0b400;
        }
      }
    }
  }
  .box-box {
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  .btn-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 92px;
    line-height: 92px;
    background: #f7f7f7;
    border-radius: 50px;
    text-align: center;
    color: #c4c4c4;
    font-size: 28px;
    font-weight: 700;
    img {
      height: 40px;
      margin-right: 8px;
      &.active {
        display: none;
      }
    }
    &.active {
      background: #4f73ee;
      color: #fff;
      img {
        display: none;
        &.active {
          display: inline-block;
        }
      }
    }
  }
}
.session_8 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: #000;
  display: none;
  &.active {
    display: block;
  }
  .box_1 {
    background: #000;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    padding-bottom: 25px;
    padding-top: 50px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    z-index: 99;
    .btn-box {
      height: 62px;
      line-height: 60px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      color: #fff;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 10px;
      span {
        display: flex;
        align-items: center;
        .close-btn {
          margin-left: 6px;
        }
      }
      img {
        height: 30px;
        margin-right: 3px;
      }
    }
  }
  .box_2 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    background: rgba(000, 000, 000, 0.4);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 310px;
    padding-top: 75px;
    box-sizing: border-box;
    .img-box {
      position: absolute;
      top: -62px;
      width: 125px;
      height: 125px;
      border-radius: 50%;
      overflow: hidden;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bottom-box {
      text-align: center;
      h3 {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 15px;
      }
      p {
        font-size: 28px;
        color: rgba(255, 255, 255, 0.9);
      }
      .btn-box {
        height: 85px;
        line-height: 85px;
        background: #fc2b55;
        text-align: center;
        font-size: 30px;
        margin-top: 25px;
      }
    }
  }
  .video-box {
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    left: 0;
    right: 0;
    video {
      width: 100%;
      max-height: 100vh;
      object-fit: cover;
    }
  }
}
.pop-box {
  height: auto;
  .centent-box {
    padding-top: 50px;
    padding-bottom: 30px;
    .title-box {
      text-align: center;
      padding-bottom: 30px;
      border-bottom: 1px solid #dadada;
      .icon-box {
        img {
          height: 95px;
        }
      }
      p {
        font-size: 28px;
        color: #000;
      }
    }
    .body-box {
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      padding-top: 30px;
      span {
        display: block;
        height: 108px;
        line-height: 108px;
        background: #5772e8;
        text-align: center;
        color: #fff;
        font-size: 28px;
        border-radius: 60px;
      }
      .black-box {
        margin-top: 40px;
        text-align: center;
        line-height: 80px;
        font-size: 28px;
      }
    }
  }
}
</style>
