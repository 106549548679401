import Vue from "vue";
import Vuex from "vuex";
import { getToken, removeToken } from '@/utils/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: getToken() != null ? true : false,
    token: getToken() || null,
  },
  getters: {},
  mutations: {
    changeUserStatus(state) {
      state.isLogin = true;
    },
  },
  actions: {
    logout() {
      this.state.token = "";
      this.state.isLogin = false;
      removeToken();
    }
  },
});
