import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// main.js
import 'lib-flexible';
import VueClipBoard from 'vue-clipboard2';
Vue.use(VueClipBoard);

import Vant from 'vant'
import 'vant/lib/index.css'  //引入vant所有组件样式
Vue.use(Vant)  //全局注册

import './assets/css/common.css';  /* 引入公共样式 */

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
