<template>
  <div class="page-box">
    <div class="session_1">
      <div class="btn-box">これまでのコイン獲得総数</div>
      <div class="monery-box">
        <img src="@/assets/images/icon_9_1.png" alt="" />{{ total_amount }}
      </div>
      <div class="img-box">
        <img src="@/assets/images/icon_10.png" alt="" />
      </div>
    </div>
    <div class="session_2">
      <div class="title-box">
        <h3>コイン実績レポ一ト</h3>
        <p>レポ一ト∧のコイン反映は24時間程度かかります</p>
      </div>
      <div class="echarts-box">
        <v-chart class="chart" :option="option_1" autoresize />
      </div>
    </div>
    <div class="session_3">
      <div class="title-box">
        <h3>今月の獲得コイン(内訳)</h3>
      </div>
      <div class="echarts-box">
        <v-chart class="chart" :option="option_2" autoresize />
        <div class="center-box">
          <img src="@/assets/images/icon_9_1.png" alt="" />{{ this.allNub }}
        </div>
      </div>
    </div>
    <div class="session_4">
      <div class="title-box">
        <h3>コインの履歴</h3>
      </div>
      <div class="list-box">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="これ以上のタスクはありません"
            loading-text="読込中..."
            @load="get_accountlog_list"
          >
          <div class="item-box" v-for="(item, index) in history_list" :key="index">
            <div class="info-box">{{item.type_desc}}</div>
            <div class="monery">{{item.change_amount_desc}}コイン</div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
// 官方：按需引入echarts所需的模块
import { statistics, accountlog } from '@/api/user';
import { cutMoneyFiter } from "@/utils/format";
import { use } from "echarts/core";
import { BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

// 第三方组件模块
import VChart from "vue-echarts";

// 注册安装模块(插件)
use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  name: "History",
  components: {
    //注册组件
    VChart,
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      allNub: 0,
      total_amount: 0,
      option_1: {
        //配置选项属性
        color: ["#5772e8"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "4%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["8月", "9月", "10月", "11月", "12月"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            position: "right",
          },
        ],
        series: [
          {
            name: "取得コイン",
            type: "bar",
            barWidth: "30",
            label: {
              show: true,
              position: "top",
            },
            data: [0, 52, 200, 334, 1120],
          },
        ],
      },
      option_2: {
        color: ["#5772e8", "#60bb56", "#efb300"],
        legend: {
          top: "bottom",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["42%", "65%"],
            label: {
              show: true,
              color: "#000",
              fontSize: "16",
              formatter(param) {
                return param.name + "\n" + cutMoneyFiter(param.value);
              },
            },
            labelLine: {
              show: true,
            },
            data: [
              { value: 0, name: "タスク収益" },
              { value: 0, name: "動画収益" },
              { value: 0, name: "クラウドマシン収益" },
            ],
          },
        ],
      },
      history_list:[]
    };
  },
  mounted() {
    this.getStatistics();
  },

  methods: {
    getStatistics(){
      statistics()
        .then(data => {
          let months = [];
          let amount = [];
          this.total_amount = data.total_amount;
          this.allNub = cutMoneyFiter(data.months_task_total + data.months_video_total + data.months_robot_total);
          this.option_2.series[0].data[0].value = data.months_task_total;
          this.option_2.series[0].data[1].value = data.months_video_total;
          this.option_2.series[0].data[2].value = data.months_robot_total;
          let months_total_amount = data.months_total_amount;
          months_total_amount.map((item)=>{
            months.push(item.months);
            amount.push(item.amount);
          })
          this.option_1.xAxis[0].data = months;
          this.option_1.series[0].data = amount;
        })
        .catch(err => {
          console.log(err);
        })
    },
    get_accountlog_list() {
      var _this = this;
      var params = {
        page_no: this.page,
        page_size: this.limit,
      };
      accountlog(params)
        .then((data) => {
          _this.history_list = _this.history_list.concat(data.lists);
          this.loading = false;
          if (_this.history_list.length == data.count) {
            _this.finished = true;
          }
          _this.page++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.session_1 {
  margin-top: 40px;
  text-align: center;
  .btn-box {
    display: inline-block;
    color: #fff;
    height: 38px;
    line-height: 38px;
    background: #2d2d2d;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    font-size: 24px;
    margin-bottom: 32px;
  }
  .monery-box {
    font-size: 40px;
    color: #2d2d2d;
    font-weight: 700;
    margin-bottom: 20px;
    img {
      height: 55px;
      margin-right: 10px;
    }
  }
}
.session_2 {
  margin-top: 50px;
  .title-box {
    padding-left: 35px;
    h3 {
      font-size: 32px;
      color: #2d2d2d;
    }
    p {
      color: #2d2d2d;
      font-size: 24px;
    }
  }
  .chart {
    width: 100%;
    height: 540px;
  }
}
.session_3 {
  margin-top: 30px;
  .title-box {
    padding-left: 35px;
    h3 {
      font-size: 32px;
      color: #2d2d2d;
    }
  }
  .echarts-box {
    position: relative;
    .center-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      left: 0;
      right: 0;
      font-size: 28px;
      font-weight: 700;
      img{
        width: 61px;
        height: 64px;
        margin-right: 8px;
      }
    }
  }
  .chart {
    width: 100%;
    height: 600px;
  }
}
.session_4 {
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .title-box {
    h3 {
      font-size: 32px;
      color: #2d2d2d;
      margin-bottom: 30px;
    }
    .time-box{
      font-size: 28px;
       color: #2d2d2d;
       font-weight: 700;
    }
  }
  .list-box{
    .item-box{
      display: flex;
      justify-content: space-between;
      height: 120px;
      line-height: 120px;
      font-size: 28px;
      color:#000;
      border-bottom: 1px solid #cfcfd0;
    }
  }
}
</style>
