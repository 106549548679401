<template>
    <div class="page-box">
        <div class="session_1">
            <div class="box_1-box">
            <div class="back-box">
                <span @click="goto('/loglist')"
                ><img src="@/assets/images/jt.png" alt=""
                /></span>
            </div>
            <span>出金詳細</span>
            </div>
            <div class="haha"></div>
            <div class="content">
                <div class="title">出金金額</div>
                <div class="money">{{ content.money }}円</div>
                <div class="status">
                  <img src="@/assets/images/s1.png" v-if="content.status==1" /> 
                  <img src="@/assets/images/s2.png" v-if="content.status==2" /> 
                  <img src="@/assets/images/s4.png" v-if="content.status==3" />
                  {{ content.status_name }}
                </div>
                <div class="tips" v-if="content.status != 3">{{ content.status_name }}，詳細は個人ウォレットで確認できます</div>
                <div class="tips" v-else>{{ content.remark }}</div>
            </div>
        </div>
        <div class="line"></div>
        <div class="contents">
          <div class="lines">
                <span>出金注文番号</span>
                <span>{{ content.sn }}</span>
            </div>
            <div class="lines">
                <span>出金方法</span>
                <span>PAYPAY</span>
            </div>
            <div class="lines">
                <span>引き出し日</span>
                <span>{{ content.create_time }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { withdrawdetail } from "@/api/pay";
import { cutMoneyFiter } from "@/utils/format";
export default {
  name: "Experience",
  data() {
    return {
      id: this.$route.query.id || 0,
      content: {}
    };
  },

  mounted() {
    this.detail();
  },

  methods: {
    goto(url) {
      this.$router.push(url);
    },
    detail() {
      withdrawdetail({id: this.id})
        .then((data) => {
          this.content = data;
          this.content.money = cutMoneyFiter(data.money);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .haha {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
  }
  .box_1-box {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    z-index: 9999;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          padding-left: 30px;
          height: 40px;
        }
      }
    }
  }
  .content{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    line-height: 2;
    color: #9598a7;
    .title {
        font-size: 26px;
    }
    .money {
        font-size: 60px;
        color: #333;
    }
    .status {
        font-size: 26px;
        color: #333;
        display: flex;
        align-items: center;
        img {
            padding-right: 6px;
        }
    }
    .tips {
        font-size: 26px;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        word-break: break-all;
    }
  }
}
.red {
    color: #ff7575 !important;
}
.line {
    margin: 50px 0;
    height: 1px;
    border-top: #ececec 1px solid;
    width: 100%;
}
.contents{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    color: #9598a7;
    .lines {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 2;
        width: 100%;
        span {
            font-size: 26px;
        }
    }
}
</style>