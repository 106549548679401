<template>
  <div class="page-box">
    <div class="session_1">
      <div class="box_1-box">
        <div class="back-box">
          <span @click="goto('/employ')"
            ><img src="@/assets/images/jt.png" alt=""
          /></span>
        </div>
        <span>ウォレット履歴</span>
      </div>
      <div class="haha"></div>
      <van-tabs sticky :offset-top="1.33333+'rem'" v-model="tab_index" color="#5772e8" @change="changeTab">
        <van-tab title="チャージ" name="1">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="これ以上のタスクはありません"
            loading-text="読込中..."
            @load="get_recharge_list()"
          >
            <div class="item-box" v-for="(item, index) in recharge_list" :key="index" @click="goto(item)">
              <div class="txt-box">
                <div class="top-box">
                  <h3 v-if="item.paytypes">{{ item.paytypes.name }}</h3>
                  <p>
                    {{ item.create_time }}
                  </p>
                </div>
                <div class="bottom-box">
                  <div class="left-box">
                    <span class="monery">{{ item.money }}</span>
                  </div>
                  <div class="right-box">
                    <span>{{ item.status_name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="出金" name="2">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="これ以上のタスクはありません"
            loading-text="読込中..."
            @load="get_withdraw_list()"
          >
            <div class="item-box" v-for="(item, index) in withdraw_list" :key="index" @click="goto(item)">
              <div class="txt-box">
                <div class="top-box">
                  <h3>{{ item.payPhone }}</h3>
                  <p>
                    {{ item.create_time }}
                  </p>
                </div>
                <div class="bottom-box">
                  <div class="left-box">
                    <span class="monery">{{ item.money }}</span>
                  </div>
                  <div class="right-box">
                    <span>{{ item.status_name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { rechargeList, withdrawList } from "@/api/pay";
export default {
  name: "Experience",
  data() {
    return {
      tab_index: 0,
      recharge_list: [],
      withdraw_list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10
    };
  },

  mounted() {

  },

  methods: {
    goto(item) {
      if(!item.id){
        this.$router.push(item);
        return false;
      }
      let url = '';
      if(item.type == 1){
        url = 'rechargedetails';
      }
      if(item.type == 2){
        url = 'withdrawdetails';
      }
      this.$router.push({
        path: "/"+url,
        query: {
          id: item.id,
        },
      });
    },
    changeTab(name) {
      this.page = 1;
      if(name == 1){
        this.recharge_list = [];
        this.get_recharge_list();
      }
      if(name == 2){
        this.withdraw_list = [];
        this.get_withdraw_list();
      }
    },
    get_recharge_list() {
      let params = {
        page_no: this.page,
        page_size: this.limit,
      };
      let _this = this;
      rechargeList(params)
        .then((data) => {
          _this.recharge_list = _this.recharge_list.concat(data.lists);
          this.loading = false;
          if (_this.recharge_list.length == data.count) {
            _this.finished = true;
          }
          _this.page++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_withdraw_list() {
      let params = {
        page_no: this.page,
        page_size: this.limit,
      };
      let _this = this;
      withdrawList(params)
        .then((data) => {
          _this.withdraw_list = _this.withdraw_list.concat(data.lists);
          this.loading = false;
          if (_this.withdraw_list.length == data.count) {
            _this.finished = true;
          }
          _this.page++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .haha {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
  }
  .box_1-box {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    z-index: 9999;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          padding-left: 30px;
          height: 40px;
        }
      }
    }
  }
  .item-box {
    border-bottom: 1px solid #ddddde;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 1.6;
    .img-box {
      margin-right: 30px;
      img {
        width: 172px;
        height: 172px;
        object-fit: cover;
      }
    }
    .txt-box {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .top-box {
        h3 {
          font-size: 28px;
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-size: 26px;
          line-height: 1.6;
        }
      }
      .bottom-box {
        font-weight: 700;
        color: #000;
        display: flex;
        flex-direction: column;
        .left-box {
          .monery {
            color: #333;
            font-size: 28px;
            font-weight: 700;
            margin-right: 10px;
          }
        }
        .right-box {
          display: flex;
          justify-content: flex-end;
          span{
            color:#333;
            font-weight: 400;
            padding-right: 10px;
            padding-left: 10px;
            line-height: normal;
            position: relative;
            text-align: right;
            font-size: 20px;
            // &:before {
            //   position: absolute;
            //   content: ' ';
            //   left: -5px;
            //   top: 10px;
            //   width: 10px;
            //   height: 10px;
            //   background-color: #26c68a;
            //   border-radius: 50%;
            // }
          }
        }
      }
    }
  }
}
</style>
