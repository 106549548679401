import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import Home from "@/views/Home.vue";
import Discounts from "@/views/Discounts.vue";
import Flyer from "@/views/Flyer.vue";
import Employ from "@/views/Employ.vue";
import My from "@/views/my/My.vue";
import History from "@/views/my/History.vue";
import Experience from "@/views/my/Experience.vue";
import Information from "@/views/my/Information.vue";
import Serve from "@/views/my/Serve.vue";
import Register from "@/views/login/Register.vue";
import Login from "@/views/login/Login.vue";
import Logins from "@/views/login/Logins.vue";
import Pay from "@/views/Pay.vue";
import BuyMachine from "@/views/BuyMachine.vue";
import ProDetail from "@/views/ProDetail.vue";
import USDT from "@/views/USDT.vue";
import Cloud from "@/views/Cloud.vue";
import Loglist from "@/views/my/Loglist.vue";
import WithdrawDetails from "@/views/my/WithdrawDetails.vue";
import RechargeDetails from "@/views/my/RechargeDetails.vue";
import Payorder from "@/views/Payorder.vue";
import Selectpay from "@/views/Selectpay.vue";
import Addbank from "@/views/Addbank.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: { index: 0, title: '首页',needLogin: false },
    component: Home,
  },
  {
    path: "/discounts",
    name: "discounts",
    meta: { index: 1, title: '优惠',needLogin: false },
    component: Discounts,
  },
  {
    path: "/Flyer",
    name: "Flyer",
    meta: { index: 2, title: '传单',needLogin: false },
    component: Flyer,
  },
  {
    path: "/employ",
    name: "Employ",
    meta: { index: 3, title: '使用',needLogin: false },
    component: Employ,
  },
  {
    path: "/my",
    name: "my",
    component: My,
    meta: { index: 4, title: '我的',needLogin: false },
   
  },
  {
    path: "/cloud",
    name: "cloud",
    component: Cloud,
  },
  {
    path: "/history",
    name: "history",
    component: History,
  },
  {
    path: "/loglist",
    name: "loglist",
    component: Loglist,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logins",
    name: "logins",
    component: Logins,
  },
  {
    path: "/pay",
    name: "pay",
    component: Pay,
  },
  {
    path: "/buyMachine",
    name: "buyMachine",
    component: BuyMachine,
  },
  {
    path: "/proDetail",
    name: "proDetail",
    component: ProDetail,
  },
  {
    path: "/serve",
    name: "serve",
    component: Serve,
  },
  {
    path: "/information",
    name: "information",
    component: Information,
  },
  {
    path: "/usdt",
    name: "usdt",
    component: USDT,
  },
  {
    path: "/experience",
    name: "experience",
    component: Experience,
  },
  {
    path: "/rechargedetails",
    name: "rechargedetails",
    component: RechargeDetails,
  },
  {
    path: "/withdrawdetails",
    name: "withdrawdetails",
    component: WithdrawDetails,
  },
  {
    path: "/payorder",
    name: "payorder",
    component: Payorder,
  },
  {
    path: "/selectpay",
    name: "selectpay",
    component: Selectpay,
  },
  {
    path: "/addbank",
    name: "addbank",
    component: Addbank,
  }
];
// 在router的index.js里面加入以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
