import request from '@/utils/request'

export function robotall() {
    return request({
        url: '/api/robot/all',
        method: 'GET'
    })
}

export function robotbuy(data) {
    return request({
      url: '/api/robot/buy',
      method: 'POST',
      data
    })
}

export function checkyue(data) {
    return request({
      url: '/api/robot/checkyue',
      method: 'POST',
      data
    })
}

export function cloudnumber(data) {
    return request({
      url: '/api/robot/cloudnumber',
      method: 'POST',
      data
    })
}

export function myrobot() {
    return request({
        url: '/api/robot/myrobot',
        method: 'GET'
    })
}

export function allincome() {
    return request({
        url: '/api/robot/allincome',
        method: 'GET'
    })
}

export function zhuanyimoney(data) {
    return request({
      url: '/api/robot/zhuanyi',
      method: 'POST',
      data
    })
}