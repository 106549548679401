<!-- eslint-disable prettier/prettier -->
<template>
  <div class="page-box">
    <div class="top-fixed">
      <div class="session_1">
        <div class="nub-box" @click="gotophoto('/history')">
          <img src="@/assets/images/icon_9_1.png" alt="" />{{ user_money || 0 }}
        </div>
        <div style="margin-left:15px;" class="nub-box" @click="gotophoto('/flyer')">
          <img src="@/assets/images/icon_9_12.jpg" alt="" />{{ robot_money || 0 }}
        </div>
      </div>
    </div>
    <div class="tab-section" v-show="tab_index == 0">
      <DrawHome></DrawHome>
      <div class="session_5">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in banner"
              :key="index"
            >
              <div class="img-box" @click="gotophoto(item.url)">
                <img :src="item.picurl" :alt="item.name" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="session_6">
        <div class="title-box">
          <h3>運営おすすめ案件!</h3>
          <p>お買い物やサ一ビスを使ってキャッシュバックをもらおう</p>
        </div>
        <div class="list-box">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="これ以上のタスクはありません"
            loading-text="読込中..."
            @load="get_task_list"
          >
            <div class="item-box" v-for="(item, index) in pro_list" :key="index" @click="goto(item)">
              <div class="img-box">
                <img :src="item.picurl" alt="" />
              </div>
              <div class="txt-box">
                <div class="top-box">
                  <h3>{{ item.title }}</h3>
                  <p>
                    {{ item.smalltitle }}
                  </p>
                </div>
                <div class="bottom-box">
                  <em>></em><span class="monery">{{ item.price }}</span
                  >コイン
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
    <Tabbar></Tabbar>
  </div>
</template>
<script>
// @ is an alias to /src
import { tasklist } from "@/api/task";
import { homephoto } from '@/api/public';
import { getUserCenter } from '@/api/user';
import Tabbar from "@/components/Tabbar.vue";
import DrawHome from "@/components/DrawHome.vue";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "Home",
  components: {
    DrawHome,
    Tabbar,
    Swiper
  },
  data() {
    return {
      is_login: this.$store.state.isLogin,
      user_money: 0,
      robot_money: 0,
      tab_index: 0,
      banner: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      pro_list: []
    };
  },
  mounted() {
    if (this.is_login) {
      this.getUserInfo();
    }
    this.getPhoto();
    new Swiper(".swiper-container", {
      loop: true,
      slidesPerView: 1.2,
      spaceBetween: 15,
      observeParents: true,
      observer: true,
      autoplay: {
        delay: 2000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
    });
  },
  methods: {
    gotophoto(url) {
      if (this.is_login) {
        this.$router.push({
          path: url,
        });
      }else{
        this.$toast({
          message: "未登録!",
          onClose: ()=>{
            this.$router.push('/my');
          },
        });
      }
    },
    getPhoto(){
      var _this = this;
      homephoto()
        .then((data) => {
          _this.banner = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserInfo() {
      let that = this;
      getUserCenter()
        .then(res => {
          that.user_money = res.user_money;
          that.robot_money = res.robot_money;
        })
        .catch(err => {
          console.log(err);
        })
    },
    goto(item) {
      if(this.is_login){
        this.$router.push({
          path: "/ProDetail",
          query: {
            id: item.id,
          },
        });
      }else{
        this.$toast({
          message: "未登録!",
          onClose: ()=>{
            this.$router.push('/my');
          },
        });
      }
    },
    tabClick(index) {
      this.tab_index = index;
    },
    get_task_list() {
      var _this = this;
      var params = {
        page_no: this.page,
        page_size: this.limit,
      };
      tasklist(params)
        .then((res) => {
          _this.pro_list = _this.pro_list.concat(res.lists);
          this.loading = false;
          if (_this.pro_list.length == res.count) {
            _this.finished = true;
          }
          _this.page++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.page-box {
  padding-top: 110px;
  padding-bottom: 100px;
}
.top-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  max-width: 750px;
  margin: 0 auto;
}
.session_1 {
  height: 110px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  .nub-box {
    display: flex;
    align-items: center;
    height: 78px;
    line-height: 78px;
    border: 2px solid #e2e2e2;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    img {
      height: 50px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
.session_2 {
  height: 120px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: #fff;
  .item-box {
    position: relative;
    width: 130px;
    text-align: center;
    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      width: 50px;
      height: 8px;
      background: #5772e8;
      border-radius: 10px;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }
    .img-box {
      display: inline-block;
      position: relative;
      height: 53px;
      line-height: 53px;
      img {
        vertical-align: top;
      }
      em {
        position: absolute;
        top: -5px;
        right: -20px;
        width: 16px;
        height: 16px;
        background: #de6043;
        border-radius: 50%;
      }
      img {
        height: 40px;
        &.active {
          display: none;
        }
      }
    }
    p {
      font-size: 26px;
      color: #8c8c8c;
      line-height: 1.1;
    }
    &.active {
      &::before {
        display: block;
      }
      .img-box {
        img {
          display: none;
          &.active {
            display: inline-block;
          }
        }
      }
      p {
        color: #000;
        font-weight: 700;
      }
    }
  }
}
.session_5 {
  margin-top: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .img-box {
    img {
      width: 100%;
      border-radius: 20px;
    }
  }
}
.session_6 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 40px;
  .title-box {
    h3 {
      font-size: 32px;
      color: #000;
    }
    p {
      color: #000;
    }
  }
  .item-box {
    border-bottom: 1px solid #ddddde;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    .img-box {
      margin-right: 30px;
      img {
        width: 172px;
        height: 172px;
        object-fit: cover;
      }
    }
    .txt-box {
      width: 470px;
      box-sizing: border-box;
      .top-box {
        h3 {
          font-size: 28px;
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-size: 26px;
        }
      }
      .bottom-box {
        font-weight: 700;
        color: #000;
        display: flex;
        align-items: center;
        .no {
          color: #de6043;
          text-decoration: line-through;
          font-weight: 700;
        }
        em {
          font-weight: 700;
          color: #000;
          margin-left: 10px;
          margin-right: 10px;
        }
        .monery {
          color: #de6043;
          font-size: 32px;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }
  }
}
.session_7 {
  text-align: center;
  padding-top: 70px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .img-box {
    font-size: 0;
    img {
      height: 185px;
    }
  }
  .txt-box {
    h3 {
      font-size: 32px;
      margin-top: 40px;
      margin-bottom: 45px;
      color: #000;
    }
    h4 {
      font-size: 28px;
      color: #000;
      margin-bottom: 15px;
      img {
        height: 24px;
        margin-right: 10px;
      }
    }
    p {
      font-size: 26px;
      color: #000;
    }
    .btn-box {
      height: 108px;
      line-height: 108px;
      background: #5772e8;
      color: #fff;
      border-radius: 50px;
      margin-top: 35px;
      margin-bottom: 70px;
      font-size: 30px;
      img {
        height: 40px;
        margin-right: 10px;
        position: relative;
        top: -1px;
      }
    }
    .info-box {
      p {
        font-size: 24px;
        color: #a7a7a7;
      }
    }
  }
}
</style>
