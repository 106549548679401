<template>
  <div class="employ-box">
    <PayNoProcess  @return-orderinfo="getPayorder"></PayNoProcess>
    <div class="session_1">
      <div class="top-box">
          <span class="title-box">ウォレット</span>
          <div class="back-box" @click="goto('/loglist')">
            <span><img src="@/assets/images/jilu.png" /></span>
          </div>
      </div>
      <van-tabs v-model="tab_index" color="#5772e8">
        <van-tab title="チャージ">
          <div class="tab-all">
            <div class="list-box">
              <div
                :class="['item-box', pay_index == index ? 'active' : '']"
                v-for="(item, index) in pay_list"
                :key="index"
                @click="selectPay(index)"
              >
                <div class="centent-box">
                  <div class="img-box">
                    <img :src="item.picurl" alt="" />
                  </div>
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
            <div class="btn-box" @click="payClick">
              チャ一ジ方法を選択
            </div>
          </div>
        </van-tab>
        <van-tab title="出金">
          <div class="tab-all">
            <div class="list-box">
                <div class="imgbox_4">
                    <div class="imgbox_5">電話番号</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="payPhone" class="zz_input" placeholder="電話番号">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">PayPayの受け取りリンク</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="payLink" class="zz_input" placeholder="PayPayの受け取りリンク">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">金融機関名</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="paypay_1" class="zz_input" placeholder="金融機関名">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">支店名</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="paypay_2" class="zz_input" placeholder="支店名">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">支店番号</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="paypay_3" class="zz_input" placeholder="支店番号">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">口座名義（力ナ）</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="paypay_4" class="zz_input" placeholder="口座名義（力ナ）">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">口座名義</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="paypay_5" class="zz_input" placeholder="口座名義">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5 flex"><span>出金金額</span><span style="display: flex;align-items: center;"><img src="@/assets/images/icon_9_1.png" style="width: 16px;padding-right: 5px;" /> {{ userinfo.user_money }}</span></div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="money" class="zz_input" placeholder="出金額を入力">
                    </div>
                </div>
                <div class="imgbox_4">
                    <div class="imgbox_5">パスワード</div>
                    <div class="zz_inputbox">
                        <input type="text" v-model="pass" class="zz_input" placeholder="パスワード">
                    </div>
                </div>
            </div>
            <div class="btn-box" @click="withdrawClick">
              同意して次へ
            </div>
            <div class="txpage-content-illustrate-text">
              <p>最低出金金額は3000.00円になります 。 最低額未満なら受付致しません、返金もできません。</p>
              <p>資金の安全性を確保するため、アカウントのセキュリティ ポリシーが変更されたり、パスワードが変更されたりした場合、出金の手動審査が行われます。スタッフから電話または電子メールで連絡されるまで、しばらくお待ちください。</p>
              <p>情報の改ざんや開示を防ぐために、コンピューターとブラウザが安全であることを確認してください。</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <Kefu></Kefu>
    <Tabbar></Tabbar>
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar.vue";
import Kefu from "@/components/Kefu.vue";
import PayNoProcess from "@/components/PayNoProcess.vue";
import { paypaylist, mypayorder } from '@/api/public';
import { banklist, createtxorder } from '@/api/pay';
import { getUserCenter } from '@/api/user';
export default {
  name: "Employ",
  components: {
    Tabbar,
    PayNoProcess,
    Kefu
  },
  data() {
    return {
      tab_index: 0,
      pay_index: -1,
      pay_list: [],
      userinfo: {},
      orderinfo: {},
      payPhone: '',
      payLink: '',
      paypay_1: '',
      paypay_2: '',
      paypay_3: '',
      paypay_4: '',
      paypay_5: '',
      money: '',
      pass: ''
    };
  },
  mounted(){
    this.getUserInfo();
    this.paypay();
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    getUserInfo() {
      let that = this;
      getUserCenter()
        .then(data => {
          that.userinfo = data;
        })
        .catch(err => {
          console.log(err);
        })
    },
    getPayorder(data) {
      this.orderinfo = data;
    },
    paypay(){
      paypaylist().then((res) => {
          this.pay_list = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectPay(index) {
      this.pay_index = index;
    },
    payClick() {
      if(this.orderinfo.sn){
        this.$router.push({
            path: "/payorder",
            query: {
                order_id: this.orderinfo.sn,
                code: this.orderinfo.code
            }
        });
        return false;
      }
      if (this.pay_index >= 0) {
        let data = this.pay_list[this.pay_index];
        let name = data.name;
        let id = data.id;
        this.$router.push({
          path: "/pay",
          query: {
            name: name,
            paytype: id
          },
        });
      } else {
        this.$toast("チャージ方法を選ぶ!");
      }
    },
    withdrawClick() {
      let data = {
        'payPhone': this.payPhone,
        'payLink': this.payLink,
        'paypay_1': this.paypay_1,
        'paypay_2': this.paypay_2,
        'paypay_3': this.paypay_3,
        'paypay_4': this.paypay_4,
        'paypay_5': this.paypay_5,
        'money': this.money,
        'pass': this.pass
      }
      createtxorder(data).then((res) => {
          this.$toast({
            message: "作業完了です",
            onClose: ()=>{
              this.$router.push({
                path: "/withdrawdetails",
                query: {
                  id: res.id,
                }
              });
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.employ-box {
  padding-bottom: 150px;
}
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 45px;
  .top-box {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: #000;
    margin-bottom: 30px;
    .back-box {
      position: absolute;
      right: 0;
      top: 0;
      span {
        display: inline-block;
        img {
          height: 50px;
        }
      }
    }
  }
  .tab-all{
    margin-top: 20px;
  }
  .list-box {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .tips {
      width: 100%;
      font-size: 22px;
      display: flex;
      justify-content: center;
    }
    .tipss {
      font-size: 22px;
    }
    .red {
      color: red;
    }
    .imgbox_4 {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .imgbox_5 {
            width: 100%;
            height: 80px;
            line-height: 80px;
            text-align: left;
            font-size: 28px;
        }
        .zz_inputbox {
            width: 100%;
            height: 80px;
            line-height: 80px;
            .zz_input {
                height: 80px;
                line-height: 80px;
                font-size: 26px;
                width: 100%;
                outline: none;
                border:1px solid #dfdfdf;
                border-radius: 10px;
                padding-left: 30px;
                padding-right: 30px;
                box-sizing: border-box;
            }
        }
    }
    .item-box {
      width: 50%;
      padding: 15px;
      box-sizing: border-box;
      .centent-box {
        border: 2px solid #e4e4e4;
        text-align: center;
        border-radius: 30px;
        height: 260px;
        padding-top: 30px;
        box-sizing: border-box;
      }
      span {
        display: inline-block;
        background: #f3f4f8;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 30px;
        font-size: 24px;
      }
      &.active {
        .centent-box {
          border: 2px solid #000000;
        }
      }
      img {
        height: 140px;
        margin-bottom: 8px;
      }
    }
  }
  .btn-box {
    width: 690px;
    height: 108px;
    line-height: 108px;
    text-align: center;
    background: #5772e8;
    color: #fff;
    border-radius: 60px;
    font-size: 32px;
    margin-top: 18px;
    font-weight: 700;
  }
}
.txpage-content-illustrate-text {
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #f2f2f2;
    padding: 20px;
    margin-top: 30px;
}
.txpage-content-illustrate-text>p {
    font-size: 26px;
    text-align: left;
    margin: 20px 0;
    word-break: break-all;
    word-wrap: break-word;
}
.txpage-content-illustrate-text>p:first-child {
    color: red;
}
</style>
