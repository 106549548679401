<template>
  <div class="page-box">
    <div class="session_1">
      <div class="top-box">
        <div class="back-box">
          <span @click="goto('/my')"
            ><img src="@/assets/images/icon_35.png" alt=""
          /></span>
        </div>
        <span>友達招待</span>
      </div>
      <div class="info-box">
        <p>
          お友達があなたの招待コ一ドを入力してアカウント登録を完了すると、あなたに5,000コイン、お友達に5,000コインをプレゼント<img src="@/assets/images/icon_36.png" alt="" />
        </p>
        <span
          >※条件成立からコイン付与まで24~48時間程度かかります※詳しい条件は以下の「友達招待のへルプを見る」よりご確認ください</span
        >
      </div>
      <div class="other-box">
        <p>あなたの招待コ一ド</p>
        <div class="er-box">
          <span>{{usr_id}}</span>
          <div class="icon-box" @click="copyBtn">
            <img src="@/assets/images/icon_16.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="session_2">
        <div class="btn-box">招待コ一ドをシエアする</div>
        <span>友達招待のへルプを見る</span>
    </div>
  </div>
</template>

<script>
import { getUserCenter } from '@/api/user';
export default {
  name: "Serve",
  data() {
    return {
      usr_id: '',
    };
  },

  mounted() {
    this.getUserInfo();
  },

  methods: {
    getUserInfo() {
      let that = this;
      getUserCenter()
        .then(response => {
          that.usr_id = response.sn;
        })
        .catch(err => {
          console.log(err);
        })
    },
    copyBtn(){
      this.$copyText("" + this.usr_id + "").then(e=> {
        this.$toast("コピー成功!");
      });
    },
    goto(url){
      this.$router.push(url);
    }
  },
};
</script>

<style lang="scss" scoped>
.session_1{
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .top-box {
    position: relative;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    .back-box {
      position: absolute;
      left: 0;
      span {
        display: inline-block;
        padding-right: 30px;
        img {
          height: 40px;
        }
      }
    }
  }
 
  .info-box{
    p{
      font-size: 28px;
      color:#000;
      margin-bottom: 10px;
      img{
        position: relative;
        top: -1px;
        height: 37px;
      }
    }
    span{
      font-size: 24px;
      color:#7d7d7d;
    }
  }
  .other-box{
    margin-top: 55px;
    p{
      font-weight: 700;
      font-size: 30px;
    }
    .er-box{
      position: relative;
      height: 95px;
      line-height: 94px;
      border-bottom: 1px solid #c1c1c2;
      font-size: 26px;
      color:#000;
      .icon-box {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 32px;
        }
      }
    }
  }
}
.session_2{
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 32px;
  .btn-box{
    height:108px ;
    line-height: 108px;
    background: #5772e8;
    color:#fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    margin-bottom: 15px;
  }
  span{
    display: block;
      height:108px ;
    line-height: 108px;
    text-align: center;
    font-size: 30px;
  }
}
</style>
