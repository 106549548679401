<template>
  <div class="page-box">
    <div class="session_1">
      <div class="back-box">
        <span @click="goto('/employ')"
          ><img src="@/assets/images/icon_35.png" alt=""
        /></span>
      </div>
      <div class="box_1">
        <h3>充值 USDT</h3>		
        <span><img src="@/assets/images/icon_37.png" alt="" />接收</span>
      </div>
      <div class="box_2">
        <div class="img-box">
          <img src="@/assets/images/img_8.jpg" alt="" />
        </div>
        <p>该地址仅支持充值USDT。</p>
      </div>
    </div>
    <div class="session_2">
      <div class="item-box">
          <div class="txt_1">
            <span>主网</span>
            <p>Tron (TRC20)</p>
          </div>
          <div class="icon-box"><img src="@/assets/images/icon_38.png" alt="" /></div>
      </div>
       <div class="item-box">
          <div class="txt_1">
            <span>USDT 充值地址</span>
            <p>{{usdt_ad}}</p>
          </div>
          <div class="icon-box" @click="copyBtn"><img src="@/assets/images/icon_39.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'USDT',

  data() {
    return {
      usdt_ad: 'TXbdN8qPgDB8vX4k9SD1yLngTFZF1TrcLJ',
    };
  },

  mounted() {
    
  },

  methods: {
    goto(url) {
      this.$router.push(url);
    },
     copyBtn(){
      this.$copyText(this.usdt_ad).then(
	      e=>{
          this.$toast("コピー成功!");
	      },
      )
    },
  },
};
</script>

<style lang="scss" scoped>
.session_1 {
  padding-left: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f3f4f6;
  padding-bottom: 50px;
  margin-bottom: 10px;
  .back-box {
    height: 100px;
    line-height: 100px;
    span {
      display: inline-block;
      padding-right: 30px;
      img {
        height: 40px;
      }
    }
  }
  .box_1{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 60px;
    h3{
      font-size: 38px;
      color:#000;
    }
    span{
      display: inline-block;
      height:50px ;
      line-height: 50px;
      line-height: fbf7eb;
      background: #fbf7eb;
      padding-left: 18px;
      border-top-left-radius:10px;
      border-bottom-left-radius:10px;
      padding-right: 30px;
      img{
        height: 22px;
        margin-right: 14px;
      }
    }
  }
  .box_2{
    text-align: center;
    .img-box{
      img{
        width: 360px;
      }
    }
    p{
      font-size: 26px;
      color:#6d7686;
    }
  }
}
.session_2{
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .item-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #f3f4f6;
    .txt_1{
      width: 70%;
      span{
        font-size: 26px;
        color:#6d7686;
      }
      p{
        font-size: 28px;
        color:#000;
        font-weight: 700;
        word-break:break-all;
      }
    }
    .icon-box{
      img{
        height: 40px;
      }
    }
  }
}
</style>