<!-- eslint-disable prettier/prettier -->
<template>
  <div class="page-box">
    <div class="session_1">
      <div class="centent-box">
        <img :src="picurl" :alt="title" />
      </div>
      <div class="title-box">{{ title }}</div>
      <div class="info-box">
        <div class="title">コイン対象条件</div>
        <div class="des-box">
          <div class="txt_1">
            <span style="padding-right:10px;">
              <img
                src="@/assets/images/icon_9_1.png"
                alt=""
              />{{ smalltitle }}
            </span>
            <span style="width: 60px;display:flex;justify-content: center;border:1px #efefef solid;font-size: 14px;background: #efefef;color:#919191">
              {{ msgtxt }}
            </span>
          </div>
          <div class="txt_2">{{ price }} コイン</div>
          <div class="txt_3">
            <span class="active">獲得目安</span>
            <span>即時反映</span>
          </div>
        </div>
      </div>
    </div>
    <div class="session_2"></div>
    <div class="session_3">
      <div class="tab-box">
        <span
          :class="outline_index == index ? 'active' : ''"
          @click="tabClick(index)"
          v-for="(item, index) in outline_list"
          :key="index"
          >{{ item.tab_name }}</span
        >
      </div>
      <div class="all-box">
        <div
          :class="['tab-item', outline_index == index ? 'active' : '']"
          v-for="(item, index) in outline_list"
          :key="index"
        >
          <div class="title-box">{{ item.title }}</div>
          <div class="des-box">
            <p v-html="item.des"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="session_4">
      <div class="btn-box" v-if="active==true" @click="receive(id)">
        <span
          ><img src="@/assets/images/icon_34.png" alt="" /><em
            >{{ price }}コイン獲得</em
          ></span
        >任務を受領します
      </div>
      <div class="btn-box" v-else @click="jump(link)">
        <span
          ><img src="@/assets/images/icon_34.png" alt="" /><em
            >{{ price }}コイン獲得</em
          ></span
        >詳しく見てみる
      </div>
    </div>
  </div>
</template>

<script>
import { taskshow, tasksreceive } from "@/api/task";
export default {
  name: "WorkspaceJsonProDetail",
  data() {
    return {
      id: this.$route.query.id || 0,
      active: true,
      select_active: false,
      outline_index: 0,
      price: 0,
      title: '',
      smalltitle: '',
      picurl: '',
      link: '',
      msgtxt: '未受領',
      outline_list: [
        {
          tab_name: "サ一ビス概要",
          title: "サ一ビス概要[PR]",
          des: "",
        },
        {
          tab_name: "コイン対象条件",
          title: "コイン対象条件[PR]",
          des: "",
        },
      ],
    };
  },

  mounted() {
    this.detail();
  },

  methods: {
    detail() {
      taskshow({id: this.id})
        .then((data) => {
          let prodetail = data;
          this.title = prodetail.title;
          this.smalltitle = prodetail.smalltitle;
          this.link = prodetail.link;
          this.picurl = prodetail.picurl;
          this.outline_list[0].des = prodetail.desc;
          this.outline_list[1].des = prodetail.condition;
          this.price = prodetail.price;
          if(prodetail.receive){
            this.active = false;
            if(prodetail.receive.status == 1){
              this.msgtxt = "審查中";
            }else if(prodetail.receive.status == 2){
              this.msgtxt = "完成";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabClick(index) {
      this.outline_index = index;
    },
    jump(link){
      location.href = link;
    },
    receive(id) {
      tasksreceive({id})
        .then(response => {
          this.$toast({
            message: "受け取り成功しました",
            onClose: ()=>{
              this.msgtxt = "審查中";
              this.active = false;
              this.detail();
            },
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 220px;
}
.session_1 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  padding-top: 30px;
  .centent-box {
    background: #f7f7f7;
    width: 100%;
    height: 333px;
    border-radius: 30px;
    overflow: hidden;
    text-align: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .title-box {
    font-size: 34px;
    color: #000;
    font-weight: 700;
    margin-top: 35px;
  }
  .info-box {
    border: 2px solid #de6043;
    border-radius: 30px;
    overflow: hidden;
    margin-top: 35px;
    padding-bottom: 15px;
    .title {
      height: 54px;
      line-height: 54px;
      background: #de6043;
      color: #fff;
      padding-left: 20px;
      font-size: 28px;
    }
    .des-box {
      padding-left: 75px;
      box-sizing: border-box;
      padding-top: 35px;
      .txt_1 {
        position: relative;
        font-size: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        span {
          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -60px;
            height: 50px;
          }
        }
      }
      .txt_2 {
        color: #f28483;
        font-weight: 700;
        font-size: 30px;
      }
      .txt_3 {
        margin-top: 35px;
        span {
          display: inline-block;
          height: 52px;
          line-height: 52px;
          padding-left: 20px;
          padding-right: 20px;
          &.active {
            font-weight: 700;
            background: #efefef;
          }
        }
      }
    }
  }
}
.session_2 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 53px;
  .title-box {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 40px;
  }
  .other-box {
    display: flex;
    align-items: center;
    line-height: normal;
    color: #000;
    font-size: 26px;
    .van-icon {
      font-size: 40px;
      &.active {
        display: none;
      }
    }
    &.active {
      .van-icon {
        display: none;
        &.active {
          display: inline-block;
        }
      }
    }
  }
}
.session_3 {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  .tab-box {
    display: flex;
    margin-bottom: 48px;
    span {
      flex: 1;
      height: 75px;
      line-height: 75px;
      background: #efefef;
      margin-right: 20px;
      text-align: center;
      color: #919191;
      font-size: 30px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      &.active {
        background: #2d2d2d;
        color: #fff;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .all-box {
    .tab-item {
      display: none;
      &.active {
        display: block;
      }
      .title-box {
        font-weight: 700;
        font-size: 28px;
      }
      .des-box {
        p {
          font-size: 26px;
          line-height: 1.6;
        }
      }
    }
  }
}
.session_4 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffff;
  padding-top: 55px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  padding-bottom: 35px;
  z-index: 999;
  .btn-box {
    height: 110px;
    line-height: 110px;
    background: #5772e8;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    position: relative;
    em {
      position: relative;
      z-index: 99;
    }
    span {
      height: 48px;
      line-height: 48px;
      background: #de6043;
      color: #fff;
      display: inline-block;
      position: absolute;
      font-size: 26px;
      font-weight: 400;
      padding-left: 64px;
      padding-right: 28px;
      border-radius: 30px;
      left: 0;
      top: -24px;
      img {
        position: absolute;
        left: 0;
        height: 60px;
      }
    }
  }
}
</style>
